import {AdditionalInfoTreeNode} from "../../AdditionalInfo";
import {InfoTypeId} from "../../../../../interfaces/additionalInfo";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import {UserRole} from "../../../../../interfaces/user";
import s from "./index.module.css";
import {DeleteFilled, EditFilled} from "@ant-design/icons";

interface InfoColumnItemProps {
  item: AdditionalInfoTreeNode;
  idKey: InfoTypeId;
  handleChangeItem: (item: AdditionalInfoTreeNode) => void;
  setDeletingItem: (item: AdditionalInfoTreeNode) => void;
  level?: number;
}

export const InfoColumnItem = ({
                          item,
                          idKey,
                          handleChangeItem,
                          setDeletingItem,
                          level = 0,
                        }: InfoColumnItemProps) => {
  const role = useSelector((state:RootState) => state.session.role);
  const isUserAdmin = role === UserRole.Admin;

  return (
    <div
      className={`${s.columnItem} ${level === 0 ? s.topLevel : ""} ${
        level % 2 !== 0 ? s.even : ""
      }`}
      key={item[idKey]}
    >
      <div className={s.itemContent}>
        <p
          className={s.columnItemName}
        >{`[${item.used_times}] ${item.name}`}</p>
        <div className={s.columnItemBtns}>
          <EditFilled
            onClick={() => handleChangeItem(item)}
            className={s.editField}
          />
          {isUserAdmin && (
            <DeleteFilled
              onClick={() => setDeletingItem(item)}
              className={s.deleteFields}
            />
          )}
        </div>
      </div>
      {item?.children?.length !== 0 && (
        <div className={s.itemChildren}>
          {item?.children?.map((child) => (
            <InfoColumnItem
              key={child[idKey]}
              level={level + 1}
              item={child}
              idKey={idKey}
              handleChangeItem={handleChangeItem}
              setDeletingItem={setDeletingItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};
