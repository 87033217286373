import s from './Footer.module.css';
import {VERSION} from "../../../config/version";

type FooterPropsT = {
  fullWidth?: boolean;
}

export const Footer = ({fullWidth}: FooterPropsT) => {
  const dateNow = new Date()
  const year = dateNow.getFullYear();

  return (
    <footer style={fullWidth ? {width: '100%', left: '0'}: {}} className={s.footer}>
      <p>Coppyright © {year} Lease Locator</p>
      <div className={s.versionContainer}>
        <label>version</label>
        <span>{VERSION}</span>
      </div>
    </footer>
  )
}
