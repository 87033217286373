import s from "./CustomCheckbox.module.css";

interface Props {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const CustomCheckbox = ({ checked, onChange, label }: Props) => (
  <label className={s.container}>
    <div className={s.label}>{label}</div>
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span className={s.checkmark} />
  </label>
);

export default CustomCheckbox;
