import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UserRole} from "../../../interfaces/user";
import {useNavigate, useParams} from "react-router-dom";
import {useCompanyApi} from "../../../hooks/useCompanyApi";
import {useCallback, useEffect, useState} from "react";
import {Company, Location, SubscriptionType, SubscriptionTypeMap} from "../../../interfaces/company";
import {mainAPI} from "../../../api/configureAxios";

export interface UpdateLocationStatusParams {
  companyId: number;
  locationId: number;
  subscriptionType: SubscriptionType
}

export interface LocationWithCompanyId extends Location {
  companyId: number;
}

export const useLocationListLogic = () => {
  const {host, role} = useSelector((state: RootState) => state.session);
  const { company } = useSelector((state:RootState) => state.company);
  const isUserAdmin = role === UserRole.Admin;
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const navigate = useNavigate();
  const {error, setError, getCompanies, getAdminCompanies} = useCompanyApi();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [locations, setLocations] = useState<(Location & { companyId: number })[]>([]);
  const dispatch = useDispatch()
  const {companyId} = useParams();

  useEffect(() => {
    if (host && isUserAdmin) {
      navigate(`/location-list/${host}`);
    }
    //eslint-disable-next-line
  }, [host, isUserAdmin]);

  const fetchCompanies = useCallback(async () => {
    try {
      let res: { companiesActive: Company[] };
      if (isPrivilegedUser) {
        res = await getAdminCompanies();
      } else {
        res = await getCompanies();
      }
      setCompanies(res.companiesActive);
      if (companyId || (company && company.companyId)) {
        const companyID = company ?  company.companyId : companyId || 0;
        const companyEl = res.companiesActive.find(
          (el) => el.companyId === +companyID
        );
        if (companyEl) {
          setLocations(companyEl.companyLocations as typeof locations);
        }
      } else {
        const locationsArr = res.companiesActive.flatMap((el) =>
          el.companyLocations.map((loc) => ({
            ...loc,
            companyId: el.companyId,
          }))
        );
        setLocations(locationsArr);
      }
    } catch (err: any) {
      console.log(err.message);
    }
    //eslint-disable-next-line
  }, [companyId, isPrivilegedUser, dispatch, company]);

  const getLocationSubscriptionTypeUsedSeat = useCallback((location: Location) => {
    return location.locationSubscriptionType === SubscriptionType.Premium ? 1 : 0
  }, [])

  useEffect(() => {
    fetchCompanies();
    //eslint-disable-next-line
  }, [companyId, isUserAdmin, company]);

  const getLocationStatusUpdateParams = (location: LocationWithCompanyId):UpdateLocationStatusParams => {
    let subscriptionType = SubscriptionType.Standard
    if(location.locationSubscriptionType === SubscriptionType.Standard){
      subscriptionType = SubscriptionType.Premium;
    }
    // @ts-ignore
    return {subscriptionType, locationId: location.locationId, companyId: location.companyId ? location.companyId : +companyId}
  }

  const updateLocationMembership = useCallback(async ({
                                                        subscriptionType,
                                                        locationId,
                                                        companyId
                                                      }: UpdateLocationStatusParams) => {
    try {
      const user = localStorage.getItem("user");
      if (!user) {
        throw new Error("Not Authorized");
      }
      const config = {
        headers: {Authorization: `Bearer ${user}`},
      };
      const payload = {
        status: SubscriptionTypeMap[subscriptionType],
        companyId,
      };

      await mainAPI.put(
        `companies/locations/status/${locationId}`,
        payload,
        config
      );
      await fetchCompanies()
    } catch (err: any) {
      console.log(err.message);
      setError(err.message);
      throw new Error(err.message);
    }
  }, [setError, fetchCompanies])


  const canUpdate = useCallback((location: LocationWithCompanyId):boolean => {
    // @ts-ignore
    const locationCompanyId = location.companyId ? location.companyId : +companyId
    const currentCompany = companies.find(company => company.companyId === locationCompanyId);
    const seatsForCompany = currentCompany?.seats || 0
    const seatsUsed: number = currentCompany?.companyLocations.reduce((total, location) =>
      total + getLocationSubscriptionTypeUsedSeat(location),0) || 0;
    const canUpdate = seatsForCompany > seatsUsed;

    return (location.locationSubscriptionType === SubscriptionType.Standard && canUpdate)
      || location.locationSubscriptionType === SubscriptionType.Premium;

  },[companies, getLocationSubscriptionTypeUsedSeat, companyId])

  return {
    companies,
    locations,
    isUserAdmin,
    isPrivilegedUser,
    error,
    setError,
    companyId,
    updateLocationMembership,
    getLocationStatusUpdateParams,
    canUpdate
  }
}
