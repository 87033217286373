import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UserRole} from "../../../interfaces/user";
import {useCompanyApi} from "../../../hooks/useCompanyApi";
import {ChangeEvent, FormEvent, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {sortAdditionalInfoByName} from "../AdditionalInfo/helper";
import {convertToBase64} from "../../../helpers/base64Convertor";
import {INITIAL_ERRORS} from "./CompanyInfo";
import {addBanner} from "../../../store/banner/bannerSlice";
import {CompanyFormI, CompanyFormIKeysT} from "./companyFormTypes";
import {AdditionalInfoData} from "../../../interfaces/additionalInfo";
import {CompanyPayloadI} from "../../../interfaces/company";

export const useCompanyInfoLogic = () => {
  const role = useSelector((state: RootState) => state.session.role);
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const dispatch = useDispatch();
  const {
    error,
    setError,
    saveCompany,
    getCreateInfo,
    getCompanyById,
    updateCompany,
    success,
    setSuccess,
    getCompanies,
    uploadCompanyLogo,
    getAdminCompanyById,
  } = useCompanyApi();
  const [company, setCompany] = useState<CompanyFormI>({
    logo: {
      name: "Company Logo",
      fieldType: "image",
      value: null,
    },
    name: {
      name: "Company Name",
      value: "",
      valid: true,
      fieldType: "input",
    },
    description: {
      name: "Description",
      value: "",
      valid: true,
      fieldType: "textarea",
    },
    website: {
      name: "Website",
      value: "",
      valid: true,
      fieldType: "input",
    },
    linkedin: {
      name: "Linkedin",
      value: "",
      valid: true,
      fieldType: "input",
    },
    categories: {
      name: "Category",
      valid: true,
      value: [],
      fieldType: "select",
    },
    certifications: {
      name: "Certifications",
      valid: true,
      value: [],
      fieldType: "select",
    },
    memberships: {
      name: "Memberships",
      valid: true,
      value: [],
      fieldType: "select",
    },
    services: {
      name: "Services",
      valid: true,
      value: [],
      fieldType: "select",
    },
  });
  const [errors, setErrors] = useState<Record<string, string>>(INITIAL_ERRORS);
  const [memberships, setMemberships] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [goToHomePage, setGoToHomePage] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { companyId } = useParams();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const res = await getCompanies();
        if (res.companiesActive.length && !isPrivilegedUser && !companyId) {
          setGoToHomePage(true);
        }
      } catch (err: any) {
        console.log(err.message);
      }
    };
    fetchCompanies();
    // eslint-disable-next-line
  }, []);

  const clearFields = useCallback(() => {
    const companyCopy: CompanyFormI = { ...company };
    const companyFormKeys: CompanyFormIKeysT[] = Object.keys(companyCopy) as CompanyFormIKeysT[]
    companyFormKeys.forEach((key) => {
      switch (companyCopy[key].fieldType) {
        case "input":
          companyCopy[key].value = "";
          break;
        case "image":
          companyCopy[key].value = "";
          break;
        case "select":
          companyCopy[key].value = [];
          break;
        default:
          break;
      }
    });
    setCompany(companyCopy);
  }, [company]);

  const getInfo = async () => {
    try {
      const res = await getCreateInfo();
      setMemberships(res.memberships.sort(sortAdditionalInfoByName));
      setCertifications(res.certifications.sort(sortAdditionalInfoByName));
      setServices(res.services.sort(sortAdditionalInfoByName));
      setCategories(res.categories.sort(sortAdditionalInfoByName));
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const fetchCompany = async (id: number) => {
    try {
      let res;
      if (isPrivilegedUser) {
        res = await getAdminCompanyById(id);
      } else {
        res = await getCompanyById(id);
      }
      const companyCopy: CompanyFormI = { ...company };
      companyCopy.name.value = res.companyName;
      companyCopy.website.value = res.companyWebsite;
      companyCopy.description.value = res.companyDescription;
      companyCopy.logo.value = res.companyLogo;
      companyCopy.linkedin.value = res.companyLinkedin;
      companyCopy.categories.value = res.companyCategory
        ? res.companyCategory
          .map((category: AdditionalInfoData) => category.categories_id)
          .map((el:number) => +el)
        : [];
      companyCopy.certifications.value = res.companyCertifications
        ? res.companyCertifications
          .map((certification: AdditionalInfoData) => certification.certifications_id)
          .map((el:number) => +el)
        : [];
      companyCopy.services.value = res.companyServices
        ? res.companyServices
          .map((service: AdditionalInfoData) => service.service_id)
          .map((el:number) => +el)
        : [];
      companyCopy.memberships.value = res.companyMemberships
        ? res.companyMemberships
          .map((membership: AdditionalInfoData) => membership.memberships_id)
          .map((el:number) => +el)
        : [];
      setCompany(companyCopy);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchCompany(+companyId);
    } else {
      clearFields();
    }
    getInfo();
    // eslint-disable-next-line
  }, [companyId]);

  const saveCompanyHandler = useCallback(
    async (data: CompanyPayloadI) => {
      try {
        const createdCompanyResponse = await saveCompany(data);
        if (createdCompanyResponse.companyId && file) {
          const converted = await convertToBase64(file);
          const logoName = data.companyName.replace(" ", "_") + "_logo";
          await uploadCompanyLogo(
            createdCompanyResponse.companyId,
            converted as File,
            logoName,
            file.type,
            isPrivilegedUser
          );
        }
        clearFields();
      } catch (err: any) {
        console.log(err.message);
      }
    },
    [file, clearFields, saveCompany, uploadCompanyLogo, isPrivilegedUser]
  );

  const updateCompanyHandler = async (data: CompanyPayloadI) => {
    try {
      const updatedCompany = await updateCompany(data);
      if (updatedCompany.companyId && file) {
        const converted = await convertToBase64(file);
        const logoName = data.companyName.replace(" ", "_") + "_logo";
        await uploadCompanyLogo(
          updatedCompany.companyId,
          converted as File,
          logoName,
          file.type,
          isPrivilegedUser
        );
      }
      clearFields();
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    let validationErrors: Record<string, string> = {};
    for (const [key, value] of Object.entries(company)) {
      const error = validate(key, value.value);
      if (error) {
        validationErrors[key] = error;
      }
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length) return;

    const data: CompanyPayloadI = {
      companyName: company.name.value,
      companyDescription: company.description.value,
      companyWebsite: company.website.value,
      companyLinkedin: company.linkedin.value,
      companyLogo: company.logo.value || '',
      companyCertifications: company.certifications.value,
      companyMemberships: company.memberships.value,
      companyCategory: company.categories.value,
      companyServices: company.services.value,
    };
    if (companyId) {
      data.companyId = +companyId;
      updateCompanyHandler(data);
    } else {
      saveCompanyHandler(data);
    }
  };

  const inputHandler = (e: ChangeEvent<HTMLInputElement>, key: CompanyFormIKeysT) => {
    const companyCopy = JSON.parse(JSON.stringify(company));
    companyCopy[key].value = e.target.value;
    setCompany(companyCopy);
    setErrors({
      ...errors,
      [key]: validate(key, e.target.value),
    });
  };

  const validate = (name: string, value:string | any[]) => {
    switch (name) {
      case "name":
        if(!value.length) {
          dispatch(addBanner({bannerMessage: "Company Name - Can not be blank", bannerStatus: 'error'}))
          return "Can not be blank."
        } else {
          return ""
        }
      case "description":
        if(!value.length) {
          dispatch(addBanner({bannerMessage: "Company Description - Can not be blank", bannerStatus: 'error'}))
          return "Can not be blank."
        } else {
          return ""
        }
      case "categories":
        if (!value.length) {
          dispatch(addBanner({bannerMessage: "Categories - You must choose at least one.", bannerStatus: 'error'}))
          return "You must choose at least one.";
        } else {
          return "";
        }
      case "services": {
        if (!value.length) {
          dispatch(addBanner({bannerMessage: "Services - You must choose at least one.", bannerStatus: 'error'}))
          return "You must choose at least one.";
        } else {
          return "";
        }
      }
      case "website":
      case "linkedin":
        const link: string = value as string
        if(link.length){
          if(!link.startsWith('https://') && !link.startsWith('http://')){
            return "Make sure your URL starts with 'https://' or 'http://'"
          }else{
            return ""
          }
        }else {
          return ""
        }
      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    if(success) {
      dispatch(addBanner({bannerMessage: success, bannerStatus: 'success'}))
    }
  }, [success, dispatch])

  return {
    company,
    error,
    setError,
    success,
    setSuccess,
    inputHandler,
    errors,
    setCompany,
    categories,
    certifications,
    memberships,
    services,
    goToHomePage,
    companyId,
    submitHandler,
    setFile,
  }
}
