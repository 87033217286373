import { useState } from "react";

import ConfirmationPopUp from "../../../../UI/ConfirmationPopUp/ConfirmationPopUp";

import s from "./index.module.css";
import { ActiveItem, AdditionalInfoTreeNode } from "../../AdditionalInfo";
import {
  AdditionalInfoData,
  InfoTitles,
  InfoTypeId,
  mapInfoTypeById,
} from "../../../../../interfaces/additionalInfo";
import {InfoColumnItem} from "../InfoColumnItem";

interface Props {
  title: string;
  items: AdditionalInfoTreeNode[];
  idKey: InfoTypeId;
  openModal: () => void;
  setActiveItem: (item: ActiveItem) => void;
  deleteInfoItem: (type: string, id: number) => void;
  level?: number;
}

export const InfoColumn = ({
  title,
  items,
  idKey,
  openModal,
  setActiveItem,
  deleteInfoItem,
}: Props) => {
  const [deletingItem, setDeletingItem] = useState<AdditionalInfoData | null>(
    null
  );
  const handleChangeItem = (item: AdditionalInfoTreeNode | null) => {
    openModal();
    setActiveItem({
      key: idKey,
      id: item?.[idKey] ?? null,
      name: item?.name ?? null,
      category: mapInfoTypeById[idKey],
      parentId: item?.parent_id ?? null,
    });
  };

  const handleDeleteItem = async () => {
    try {
      await deleteInfoItem(InfoTitles[idKey], deletingItem?.[idKey] as number);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setDeletingItem(null);
    }
  };

  return (
    <>
      {deletingItem && (
        <ConfirmationPopUp
          message={`Confirm delete of ${InfoTitles[idKey]} ${deletingItem.name}`}
          onCancel={() => {
            setDeletingItem(null);
          }}
          onSubmit={handleDeleteItem}
        />
      )}
      <div className={s.column}>
        <h1 className={s.title}>{title}</h1>
        <div className={s.columnItems}>
          {items.map((item) => (
            <InfoColumnItem
              key={item[idKey]}
              item={item}
              idKey={idKey}
              handleChangeItem={handleChangeItem}
              setDeletingItem={setDeletingItem}
            />
          ))}
        </div>
        <button className={s.addButton} onClick={() => handleChangeItem(null)}>
          + Add new {InfoTitles[idKey]}
        </button>
      </div>
    </>
  );
};
