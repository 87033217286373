import s from "./ConfirmationPopUp.module.css";

import { PopUp } from "../PopUp/PopUp";

interface Props {
  message: string;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function ConfirmationPopUp({
  message,
  onSubmit,
  onCancel,
}: Props) {
  return (
    <PopUp onClose={onCancel}>
      <h1 className={s.title}>{message}</h1>
      <div className={s.buttonGroup}>
        <button className={s.cancelButton} onClick={onCancel}>
          Cancel
        </button>
        <button className={s.confirmButton} onClick={onSubmit}>
          Confirm
        </button>
      </div>
    </PopUp>
  );
}
