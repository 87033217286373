import { AdditionalInfoData } from "./additionalInfo";

export enum CompanyStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export const CompanyStatusLabel: Record<CompanyStatus, string> = {
  [CompanyStatus.Pending]: "Pending",
  [CompanyStatus.Active]: "Active",
  [CompanyStatus.Inactive]: "Inactive",
};

export enum SubscriptionType {
  Standard = "standard",
  Premium = "premium",
}

export const SubscriptionTypeLabel: Record<SubscriptionType, string> = {
  [SubscriptionType.Standard]: "Standard",
  [SubscriptionType.Premium]: "Premium",
};

export const SubscriptionTypeMap = {
  [SubscriptionType.Standard]: 0,
  [SubscriptionType.Premium]: 1,
};

export interface Location {
  locationId: number;
  locationName: string;
  locationAddress: string;
  locationCity: string;
  locationProvince: string;
  locationPostalcode: string;
  locationCountry: string;
  locationCode: string;
  locationLatitude: string;
  locationLongitude: string;
  locationSubscriptionType: SubscriptionType;
  companyContacts: {
    id: number;
    name: string;
    email: string;
    phone: string;
  }[];
}

export interface CompanyPayloadI {
  companyId?: number;
  companyName: string;
  companyDescription: string;
  companyWebsite: string;
  companyLinkedin: string;
  companyLogo: string;
  companyCertifications: AdditionalInfoData[];
  companyMemberships: AdditionalInfoData[];
  companyCategory: AdditionalInfoData[];
  companyServices: AdditionalInfoData[];
}

export interface Company extends CompanyPayloadI{
  companyId: number;
  companyCreated: string;
  companyUpdated: string;
  companyLogoThumbnail: string;
  companyStatus: CompanyStatus;
  companySubscriptionType: SubscriptionType;
  companyLocations: Location[];
  companyLocationCount: number;
  seats: number;
  subscriptionId: string | null;
}

