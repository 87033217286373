//@ts-nocheck
import { useCallback, useState } from 'react'
import { mainAPI } from "../api/configureAxios";

export const useLocationApi = () => {
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState(null)

  const saveLocation = useCallback(async ({ location, contacts }) => {
    try {
      const user = localStorage.getItem('user')
      if (!user) {
        throw new Error('Not Authorized')
      }
      const config = {
        headers: { Authorization: `Bearer ${user}` },
      }
      const { data } = await mainAPI.post(
        '/companies/locations',
        { location, contacts },
        config
      )
      if (data.success === false) {
        throw new Error(data.message)
      } else {
        setSuccess(data.message)
      }
      return data
    } catch (err) {
      const message = err.response?.data?.message
      setError(message || err.message)
      throw new Error(err.message)
    }
  },[])

  const getLocation = useCallback(async ({ companyId, locationId }) => {
    try {
      const user = localStorage.getItem('user')
      if (!user) {
        throw new Error('Not Authorized')
      }
      const config = {
        headers: { Authorization: `Bearer ${user}` },
      }

      const { data } = await mainAPI.get(
        `/companies/user-companies/${companyId}`,
        config
      )

      if (!data.success) {
        throw new Error(data.message)
      }
      return data.data.companyLocations.find((el) => el.locationId === +locationId)
    } catch (err) {
      const message = err.response?.data?.message
      setError(message || err.message)
      throw new Error(err.message)
    }
  }, [])

  const getLocationAdmin = useCallback(async ({ companyId, locationId }) => {
    try {
      const user = localStorage.getItem('user')
      if (!user) {
        throw new Error('Not Authorized')
      }
      const config = {
        headers: { Authorization: `Bearer ${user}` },
      }

      const { data } = await mainAPI.get(
        `/admin/company/${companyId}`,
        config
      )

      if (!data.success) {
        throw new Error(data.message)
      }
      return data.data.companyLocations.find((el) => el.locationId === +locationId)
    } catch (err) {
      const message = err.response?.data?.message
      setError(message || err.message)
      throw new Error(err.message)
    }
  }, [])

  const updateLocation = useCallback(async ({ location, contacts }) => {
    try {
      const user = localStorage.getItem('user')
      if (!user) {
        throw new Error('Not Authorized')
      }
      const config = {
        headers: { Authorization: `Bearer ${user}` },
      }
      const { data } = await mainAPI.patch(
        '/companies/locations',
        { location, contacts },
        config
      )
      if (data.success === false) {
        throw new Error(data.message)
      } else {
        setSuccess(data.message)
      }
      return data
    } catch (err) {
      const message = err.response?.data?.message
      setError(message || err.message)
      throw new Error(err.message)
    }
  },[])

  return {
    error,
    setError,
    saveLocation,
    getLocation,
    updateLocation,
    success,
    setSuccess,
    getLocationAdmin,
  }
}
