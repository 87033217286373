import ContactList from "./components/ContactList";
import FormFields from "./components/FormFields";
import { AdminHOC } from "../../HOCs/AdminHOC/AdminHOC";
import { useLocationLogic } from "./hooks/useLocationLogic";
import mainS from "../../../App.module.css";
import { Error as ErrorPopUp } from "../../UI/Error/Error";
import {Navigate} from "react-router-dom";

const FormLocation = () => {
  const {
    location,
    companyId,
    currentCompanyId,
    locationId,
    submitHandler,
    companies,
    changeHandler,
    selectHandler,
    changeCoordValues,
    showChart,
    locationError,
    setLocationError,
    success,
    error,
    setError,
    isValid,
    contacts,
    deleteContact,
    addContact,
    changeContactHandler,
  } = useLocationLogic();

  return (
    <AdminHOC title="Location Info">
      {error && (
        <ErrorPopUp
          message={error}
          close={() => {
            setError(undefined);
          }}
        />
      )}
      {locationError && (
        <ErrorPopUp
          message={locationError}
          close={() => {
            setLocationError(undefined);
          }}
        />
      )}
      {success && (
        <Navigate to={`/location-list/${currentCompanyId}`} />
      )}
      <div className={mainS.container}>
        <form onSubmit={submitHandler}>
          <FormFields
            location={location}
            showChart={showChart}
            changeHandler={changeHandler}
            changeCoordValues={changeCoordValues}
            companies={companies}
            selectHandler={selectHandler}
            companyId={companyId}
            locationId={locationId}
          />
          <ContactList
            contacts={contacts}
            deleteContact={deleteContact}
            addContact={addContact}
            changeContactHandler={changeContactHandler}
          />
          <button
            type="submit"
            className={isValid() ? undefined : mainS.invalidButton}
          >
            Save Location
          </button>
        </form>
      </div>
    </AdminHOC>
  );
};
export default FormLocation;
