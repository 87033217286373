import React from 'react';
import CustomCheckbox from "../../../../UI/CustomCheckbox/CustomCheckbox";
import {CompanyFormI} from "../../companyFormTypes";

type CategoriesServicesProps = {
  company: CompanyFormI;
  servicesCategories: {parent_id: number | null; id: number, name: string}[];
  checkHandler: (type: 'services' | 'categories', id: number) => void;
  type: 'services' | 'categories';
}

export const CategoriesServices = (
  {
    company,
    servicesCategories,
    checkHandler,
    type,
  }:CategoriesServicesProps) => {
  const parentServicesCategories = servicesCategories.filter(sc => sc.parent_id === null)
  return parentServicesCategories.map(parentSC => {
    const childSCs = servicesCategories.filter(sc => sc.parent_id === parentSC.id);
    return (
      <div style={{ display: "block" }} key={parentSC.id}>
        <CustomCheckbox
          checked={company[type].value.includes(parentSC.id)}
          onChange={() => {
            checkHandler(type, parentSC.id);
          }}
          label={parentSC.name}
        />
        <div style={{ marginLeft: "18px" }}>
          {childSCs.map((childSC) => (
            <div
              key={childSC.id}
            >
              <CustomCheckbox
                checked={company[type].value.includes(
                  childSC.id
                )}
                onChange={() => {
                  checkHandler(type, childSC.id);
                }}
                label={childSC.name}
              />
            </div>
          ))}
        </div>
      </div>
    )
  })
}
