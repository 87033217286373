import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import s from './NotFound.module.css'

export const NotFound = () => {
  const history = useNavigate()
  useEffect(() => {
    if(!localStorage.getItem('user')) {
      history('/signin')
    }
  })
  return (
    <div className={s.container}>
      <h1>Page Not Found</h1>
      <Link to={!!localStorage.getItem('user') ? '/' : '/signin'}>Home</Link>
    </div>
  )
}
