import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import s from './PhoneInputField.module.css';

interface Props {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  setError: (error: string) => void;
}

export const PhoneInputField = ({ value, onChange, error = '', setError}: Props) => {

  const onPhoneChange = (numberText: string) => {
    if(error) {
      setError('')
    }
    onChange(numberText)
  }

  return (
    <div className={s.phoneContainer} style={{ position: 'relative' }}>
      <PhoneInput
        value={value}
        country={'ca'}
        onlyCountries={['us', 'ca']}
        onChange={onPhoneChange}
      />
      {error && <span className={s.errLabel}>{error}</span>}
    </div>
  )
}
