import { CardElement } from '@stripe/react-stripe-js';
import { Button } from '../../Button';
import {useStripeLogic} from "./useStripeLocig";
import s from './SubscriptionForm.module.css';
import {useDispatch} from "react-redux";
import {addBanner} from "../../../../store/banner/bannerSlice";

type SubscriptionFormProps = {
  locationsNumber: number;
  companyId: number;
  onClose: () => void;
  refetchCompanies: () => void;
}

export default function SubscriptionForm({locationsNumber, companyId, onClose, refetchCompanies}: SubscriptionFormProps) {
  const {loading, handleSubmit, getPaymentLink, isLinkLoading} = useStripeLogic();
  const dispatch = useDispatch();

  const handleGenerateLinkClick = async () => {
    const link = await getPaymentLink(locationsNumber, companyId);
    await navigator.clipboard.writeText(link);
    dispatch(addBanner({bannerMessage: 'Link copied to clipboard', bannerStatus: 'info'}))
  }

  return (
    <>
      <form className={s.form} onSubmit={(e) => handleSubmit(e, locationsNumber, companyId, onClose, refetchCompanies)}>
        <div className={s.cardContainer}>
          <CardElement />
        </div>
        <div>
        <Button className={s.subscribeButton} isLoading={loading} type="submit" disabled={loading}>
          Subscribe
        </Button>
        </div>
      </form>
      <Button
        type='button'
        isLoading={isLinkLoading}
        className={s.getLinkButton}
        onClick={handleGenerateLinkClick}>
        Copy payment link to clipboard
      </Button>
    </>
  );
}
