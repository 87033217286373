export enum InfoType {
  Category = "categories",
  Certification = "certifications",
  Membership = "memberships",
  Service = "services",
}

export enum InfoTypeId {
  Category = "categories_id",
  Certification = "certifications_id",
  Membership = "memberships_id",
  Service = "service_id",
}

export const InfoTitles: Record<InfoTypeId, string> = {
  [InfoTypeId.Membership]: "membership",
  [InfoTypeId.Certification]: "certification",
  [InfoTypeId.Service]: "service",
  [InfoTypeId.Category]: "category",
};

export const mapInfoTypeById: Record<InfoTypeId, InfoType> = {
  [InfoTypeId.Category]: InfoType.Category,
  [InfoTypeId.Certification]: InfoType.Certification,
  [InfoTypeId.Membership]: InfoType.Membership,
  [InfoTypeId.Service]: InfoType.Service,
};

export type AdditionalInfoData = {
  name: string;
  parent_id?: number;
  used_times: number;
} & {
  [key in InfoTypeId]?: number;
};
