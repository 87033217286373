import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'

import s from './Error.module.css'

interface Props {
  message: string;
  close: () => void;
}

export const Error = ({ message, close }:Props) => {
  const history = useNavigate()

  const clickHandler = () => {
    if (message === 'Not authorized') {
      localStorage.removeItem('user')
      history('/signin')
    }
    close()
  }

  const Error = (
    <div className={s.blurDiv}>
      <div className={s.content}>
        <h1>Error</h1>
        <p>{message}</p>
        <button onClick={clickHandler}>OK</button>
      </div>
    </div>
  )

  return createPortal(Error, document.getElementById('error') as HTMLDivElement)
}
