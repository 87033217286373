import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {theme} from "antd";
import {Company, CompanyStatus} from "../../../interfaces/company";
import {useCompanyApi} from "../../../hooks/useCompanyApi";
import {UserRole} from "../../../interfaces/user";
import {getRoleFromLocalStorage, setHost} from "../../../store/session/sessionSlice";
import {resetCompany, setCompany} from "../../../store/company/companySlice";
import {clearStorage} from "../../../helpers/storage";

type CompanyPerStatusType =  Record<CompanyStatus, number>;

const INITIAL_COMPANY_PER_STATUS_TYPE: CompanyPerStatusType = {
  PENDING: 0,
  ACTIVE: 0,
  INACTIVE: 0,
}

export const useAdminHOCLogic = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state:RootState) => state.company);
  const [selectedCompany, setSelectedCompany] = useState(company ? company.companyId : '');
  const [companies, setCompanies] = useState<Company[]>([]);
  const { getCompanies, getAdminCompanies } = useCompanyApi();
  const [companyPerStatusCount, setCompanyPerStatusCount] =
    useState<CompanyPerStatusType>({...INITIAL_COMPANY_PER_STATUS_TYPE})
  const { role, host } = useSelector((state:RootState) => state.session);
  const [urlSearchParams] = useSearchParams();
  const status = urlSearchParams.get("status");
  const { companyId } = useParams();

  const isUserAdmin = role === UserRole.Admin;
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);

  useEffect(() => {
    if(company)
      setSelectedCompany(company.companyId);
    else{
      setSelectedCompany(0);
    }
  }, [company]);

  useEffect(() => {
    if(companyId) {
      const selectedCompany = companies.find(c => c.companyId === +companyId)
      if(selectedCompany)
        dispatch(setCompany(selectedCompany))
    }
    //eslint-disable-next-line
  }, [companyId, dispatch])

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let res: {companiesActive: Company[]}
        if ([UserRole.Admin, UserRole.Support].includes(role)) {
          res = await getAdminCompanies();
        } else {
          res = await getCompanies();
        }
        const fetchedCompanies: Company[] = res.companiesActive
          .sort((a, b) => a.companyName.localeCompare(b.companyName));
        setCompanies(fetchedCompanies);
        const activeCompaniesCount = fetchedCompanies.filter(company => company.companyStatus === "ACTIVE").length
        const pendingCompaniesCount = fetchedCompanies.filter(company => company.companyStatus === "PENDING").length
        const inactiveCompaniesCount = fetchedCompanies.filter(company => company.companyStatus === "INACTIVE").length
        setCompanyPerStatusCount({
          PENDING: pendingCompaniesCount,
          ACTIVE: activeCompaniesCount,
          INACTIVE: inactiveCompaniesCount
        })
      } catch (err) {
        //@ts-ignore
        console.log(err.message);
      }
    };
    fetchCompanies().then();
    //eslint-disable-next-line
  }, [role]);

  const history = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {

    if (!localStorage.getItem("user")) {
      history("/signin");
    }
    // eslint-disable-next-line
  }, []);

  const handleCompanySelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCompany:Company | undefined = companies.find(c => c.companyId === +e.target.value);
    dispatch(setHost(e.target.value));
    console.log('Value: ',e.target.value)
    if(selectedCompany){
      dispatch(setCompany(selectedCompany))
    }else{
      dispatch(setCompany(null))
    }
  }, [companies, dispatch])

  const handleLogout = () => {
    clearStorage()
    handleLinkClick()
    history("/signin");
  };

  const handleLinkClick = useCallback(() => {
    dispatch(resetCompany())
  }, [dispatch])

  const phoneNumber = localStorage.getItem("phone") as string;
  const roleCharacter = getRoleFromLocalStorage().charAt(0);

  return {
    companies,
    selectedCompany,
    phoneNumber,
    host,
    status,
    roleCharacter,
    isUserAdmin,
    colorBgContainer,
    isPrivilegedUser,
    handleCompanySelect,
    handleLinkClick,
    handleLogout,
    companyPerStatusCount
  }
}
