import { CompanyStatus } from "../interfaces/company";

export const getCompanyStatusColor = (companyStatus: CompanyStatus) => {
  switch (companyStatus) {
    case CompanyStatus.Active:
      return "rgb(9, 110, 9)";
    case CompanyStatus.Inactive:
      return "rgb(226, 62, 62)";
    case CompanyStatus.Pending:
      return "#0000ff";
  }
};
