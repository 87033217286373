//@ts-nocheck
import ReactInputMask from "react-input-mask";
import s from "../../index.module.css";

const ContactList = ({
  contacts,
  deleteContact,
  addContact,
  changeContactHandler,
}) => {
  const ContactList = contacts.map((contact, index) => {
    return (
      <div key={contact.localId}>
        <div className={s.contactNameContainer}>
          <p>{`Contact #${index + 1}`}</p>
          {index > 0 && (
            <div
              className={s.deleteContact}
              onClick={() => {
                deleteContact(contact.localId);
              }}
            >
              —
            </div>
          )}
        </div>

        <div className={s.contactContainer}>
          <div className={s.contactDiv}>
            <p>Contact Name:</p>
            <input
              value={contact.name}
              placeholder="Contact Name"
              onChange={(e) => {
                changeContactHandler(e, contact.localId, "name");
              }}
            />
          </div>
          <div className={s.contactDiv}>
            <p>Email:</p>
            <input
              value={contact.email}
              placeholder="email"
              pattern={"[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"}
              onChange={(e) => {
                changeContactHandler(e, contact.localId, "email");
              }}
            />
          </div>
          <div className={s.contactDiv}>
            <p>Phone:</p>
            <ReactInputMask
              mask="(999) 999-9999"
              value={contact.phone}
              onChange={(e) => {
                changeContactHandler(e, contact.localId, "phone");
              }}
            />
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      {ContactList}
      <div className={s.addContact} onClick={addContact}>
        + secondary contact
      </div>
    </>
  );
};

export default ContactList;
