import {useCallback, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import {RootState} from "../../../store";
import {UserRole} from "../../../interfaces/user";
import {useCompanyApi} from "../../../hooks/useCompanyApi";
import {addBanner} from "../../../store/banner/bannerSlice";
import {Company, CompanyStatus, SubscriptionType} from "../../../interfaces/company";
import {useSearchParams} from "react-router-dom";
import {AdditionalInfoData} from "../../../interfaces/additionalInfo";
import {setCompany} from "../../../store/company/companySlice";

export enum CompanySortOption {
  Alphabetical = "alphabetical",
  Premium = "premium",
  Standard = "standard",
  DateCreated = "dateCreated",
  DateUpdated = "dateUpdated",
}

export const CompanySortOptionLabel: Record<CompanySortOption, string> = {
  [CompanySortOption.Alphabetical]: "Alphabetical",
  [CompanySortOption.Premium]: "Premium Only",
  [CompanySortOption.Standard]: "Standard Only",
  [CompanySortOption.DateCreated]: "Date Created",
  [CompanySortOption.DateUpdated]: "Date Updated",
};

export const useCompanyListLogic = () => {
  const { role } = useSelector((state: RootState) => state.session);
  const { company } = useSelector((state: RootState) => state.company);
  const isUserAdmin = role === UserRole.Admin;
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const {
    error,
    setError,
    getCompanies,
    updateCompanyStatus,
    isLoading,
    getAdminCompanies,
    cancelCompanySubscription,
  } = useCompanyApi();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [activeCompanySortOption, setActiveCompanySortOption] =
    useState<CompanySortOption>(CompanySortOption.Alphabetical);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToUpdate, setCompanyToUpdate] = useState<Company | null>(null);
  const [approveItem, setApproveItem] = useState<Company | null>(null);
  const [deactivateItem, setDeactivateItem] = useState<Company | null>(null);
  const [isUserSubscriptionModalOpen, setIsUserSubscriptionModalOpen] =useState(false);
  const [companySubscriptionToUpdate, setCompanySubscriptionToUpdate] = useState(0)
  const [urlSearchParams] = useSearchParams();
  const status = urlSearchParams.get("status");
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('paymentStatus')
  const companyId = queryParams.get('companyId')
  const locationsNumber = queryParams.get('locationsNumber')


  const handleUpdateSubscriptionClick = useCallback((companyId: number) => {
    setIsUserSubscriptionModalOpen(true);
    setCompanySubscriptionToUpdate(companyId)
  }, [])

  const handleCancelSubscriptionClick = useCallback(async (companyId: number) => {
    await cancelCompanySubscription(companyId)
    dispatch(addBanner({bannerMessage: 'Subscription canceled', bannerStatus: 'success'}))
  },[dispatch, cancelCompanySubscription])

  useEffect(() => {
    if(paymentStatus && paymentStatus === 'success'){
      dispatch(addBanner({bannerMessage: 'Subscription active', bannerStatus: 'success'}))
    }else if(paymentStatus && paymentStatus === 'fail'){
      dispatch(addBanner({bannerMessage: 'Something went wrong on stirpe checkout', bannerStatus: 'error'}))
    }
    if(companyId && paymentStatus === 'success') {
      dispatch(setCompany(companies.filter(company => company.companyId === +companyId)[0]))
    }
  },[paymentStatus, companyId, locationsNumber, dispatch, companies])

  const fetchCompanies = useCallback(async () => {
    try {
      let res;
      if (isPrivilegedUser) {
        res = await getAdminCompanies();
      } else {
        res = await getCompanies();
      }
      setCompanies(res.companiesActive);
    } catch (err: any) {
      console.log(err.message);
    }
    //eslint-disable-next-line
  },[]);

  useEffect(() => {
    fetchCompanies().then();
  }, [isPrivilegedUser, fetchCompanies]);

  const getServices = (services: AdditionalInfoData[]) => {
    return services && services.length
      ? services.map((service) => service.name).join(", ")
      : "";
  };

  const getCertifications = (certifications: AdditionalInfoData[]) => {
    return certifications && certifications.length
      ? certifications.map((certification) => certification.name).join(", ")
      : "No certification selected";
  };

  const getMemberships = (memberships: AdditionalInfoData[]) => {
    return memberships && memberships.length
      ? memberships.map((membership) => membership.name).join(", ")
      : "No membership selected";
  };

  const getCategories = (categories: AdditionalInfoData[]) => {
    return categories && categories.length
      ? categories.map((category) => category.name).join(", ")
      : "";
  };

  const handleStatusUpdate = async (
    status: CompanyStatus,
    companyId: number
  ) => {
    try {
      await updateCompanyStatus(status, companyId);
      await fetchCompanies();
    } catch (err: any) {
      console.log(err.message);
    }
    setApproveItem(null);
    setDeactivateItem(null);
  };

  const sortCompanies = () => {
    const filteredCompanies = status
      ? companies.filter((company) => company.companyStatus === status)
      : [...companies];

    const mapSortOptions: Record<CompanySortOption, () => Company[]> = {
      [CompanySortOption.Alphabetical]: () =>
        filteredCompanies.sort((a, b) =>
          a.companyName.localeCompare(b.companyName)
        ),
      [CompanySortOption.Premium]: () =>
        filteredCompanies.filter(
          (company) =>
            company.companySubscriptionType === SubscriptionType.Premium
        ),
      [CompanySortOption.Standard]: () =>
        filteredCompanies.filter(
          (company) =>
            company.companySubscriptionType === SubscriptionType.Standard
        ),
      [CompanySortOption.DateCreated]: () =>
        filteredCompanies.sort(
          (a, b) =>
            new Date(b.companyCreated).valueOf() -
            new Date(a.companyCreated).valueOf()
        ),
      [CompanySortOption.DateUpdated]: () =>
        filteredCompanies.sort(
          (a, b) =>
            new Date(b.companyUpdated).valueOf() -
            new Date(a.companyUpdated).valueOf()
        ),
    };

    return mapSortOptions[activeCompanySortOption]();
  };

  const openSubscriptionTypeModal = useCallback((company: Company) => {
    setIsModalOpen(true);
    setCompanyToUpdate(company);
  }, []);

  const filteredCompanies =
    company && isPrivilegedUser
      ? companies.filter((c) => c.companyId === company.companyId)
      : sortCompanies();

  return {
    isPrivilegedUser,
    isUserAdmin,
    isLoading,
    setApproveItem,
    setDeactivateItem,
    handleStatusUpdate,
    deactivateItem,
    error,
    setError,
    isModalOpen,
    companyToUpdate,
    filteredCompanies,
    companies,
    getServices,
    getCategories,
    getCertifications,
    getMemberships,
    openSubscriptionTypeModal,
    dispatch,
    activeCompanySortOption,
    setActiveCompanySortOption,
    approveItem,
    fetchCompanies,
    setIsModalOpen,
    isUserSubscriptionModalOpen,
    setIsUserSubscriptionModalOpen,
    companySubscriptionToUpdate,
    handleUpdateSubscriptionClick,
    handleCancelSubscriptionClick,
  }
}
