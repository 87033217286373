import React, { useCallback, useState, useRef } from 'react';

import s from './index.module.css';

interface Props {
  imageUrl: string;
  setFile: (file: File) => void;
}

function ImageUploader({ imageUrl, setFile }:Props) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState(imageUrl);
  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    const url = URL.createObjectURL(file);
    setFileUrl(url);
    setFile(file);
  }, [setFile])

  const handleUploadClick = useCallback(() => {
    hiddenFileInput.current?.click();
  }, [hiddenFileInput]);

  return (
    <div className={s.container}>
      {(fileUrl || imageUrl) && <img src={fileUrl ? fileUrl : imageUrl} className={s.image} alt="logo"/>}
      {!(fileUrl || imageUrl) && <label className={s.label} htmlFor="file">Select a logo</label>}
      <button className={s.uploadButton} onClick={handleUploadClick}>
        {(fileUrl || imageUrl) && <div className={s.withImageContainer}>
          <p className={s.inputLabel}>Change logo</p>
        </div>}
      </button>
      <input ref={hiddenFileInput} style={{display: 'none'}} type="file" id="file" onChange={handleFileChange} />
    </div>
  );
}

export default ImageUploader;
