import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SubscriptionForm from '../SubscriptionForm';

const stripeKey = process.env.REACT_APP_ENV === 'local' ? "pk_test_7V5BWgfE07oevryi8YaaFL0Q" : "pk_live_92ByM0me7RdDqabhHedtW6G3"
const stripePromise = loadStripe(stripeKey);

type StripeContainerProps = {
  locationsNumber: number;
  companyId: number;
  onClose: () => void;
  refetchCompanies: () => void;
}

function StripeContainer({locationsNumber, companyId, onClose, refetchCompanies}: StripeContainerProps) {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm
        companyId={companyId}
        locationsNumber={locationsNumber}
        onClose={onClose}
        refetchCompanies={refetchCompanies}
      />
    </Elements>
  );
}

export default StripeContainer;
