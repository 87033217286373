import React from "react";
import s from "./index.module.css";

interface Props {
  label: string;
  value: string | number;
  isLink?: boolean;
  color?: string;
  capitalize?: boolean
}

export const LabelValue = ({ label, value, isLink = false, color, capitalize = false}: Props) => {
  return (
    <div className={s.container}>
      <span className={s.label}>{label}</span>
      {isLink && typeof value === 'string' ? (
        <a
          href={
            value ? (!value.includes("http") ? `https://${value}` : value) : ""
          }
          rel="noreferrer"
          target="_blank"
        >
          {value}
        </a>
      ) : (
        <span
          style={{
            color: color || "black",
            fontWeight: color ? "bold" : "normal",
            textTransform: capitalize ? 'capitalize' : 'none',
            width: 'calc(100% - 100px)'
          }}
        >
          {value}
        </span>
      )}
    </div>
  );
};
