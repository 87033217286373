import {Link} from "react-router-dom";
import {AdminHOC} from "../../HOCs/AdminHOC/AdminHOC";
import {Error} from "../../UI/Error/Error";
import s from "./CompanyList.module.css";
import UpdateMembershipModal from "./components/UpdateMembershipModal";
import ConfirmationPopUp from "../../UI/ConfirmationPopUp/ConfirmationPopUp";
import {CompanyStatus} from "../../../interfaces/company";
import {useCompanyListLogic} from "./useCompanyListLogic";
import {SubscriptionPopup} from "../../UI/SubscriptionPopup";
import {CompanyListView} from "./components/CompanyListView";
import {SortSelect} from "./components/SortSelect";
import {useConfirmationPopupLogic} from "../../UI/ConfirmationPopUp/confirmationPopupLogic";

const CompanyList = () => {
  const {
    isPrivilegedUser,
    isUserAdmin,
    isLoading,
    setApproveItem,
    setDeactivateItem,
    handleStatusUpdate,
    deactivateItem,
    error,
    setError,
    isModalOpen,
    companyToUpdate,
    filteredCompanies,
    companies,
    getServices,
    getCategories,
    getCertifications,
    getMemberships,
    openSubscriptionTypeModal,
    approveItem,
    fetchCompanies,
    setIsModalOpen,
    isUserSubscriptionModalOpen,
    setIsUserSubscriptionModalOpen,
    companySubscriptionToUpdate,
    handleUpdateSubscriptionClick,
    activeCompanySortOption,
    setActiveCompanySortOption,
    handleCancelSubscriptionClick,
  } = useCompanyListLogic();

  const {
    message,
    setMessage,
    showConfirmationPopup,
    setShowConfirmationPopup,
    onCancel,
    handleSubmit,
    setOnSubmit
  } = useConfirmationPopupLogic()

  const cancelSubscription = (companyId: number) => {
    setMessage("Are you sure you want to cancel subscription?")
    setShowConfirmationPopup(true)
    setOnSubmit(() => () => handleCancelSubscriptionClick(companyId).then(() => fetchCompanies()))
  }

  return (
    <>
      {showConfirmationPopup && (
        <ConfirmationPopUp
          message={message}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      )}
      {approveItem && (
        <ConfirmationPopUp
          message={`Confirm approval of ${approveItem.companyName}`}
          onCancel={() => {
            setApproveItem(null);
          }}
          onSubmit={() =>
            handleStatusUpdate(CompanyStatus.Active, approveItem.companyId)
          }
        />
      )}
      {deactivateItem && (
        <ConfirmationPopUp
          message={`Confirm deactivation of ${deactivateItem.companyName}`}
          onCancel={() => {
            setDeactivateItem(null);
          }}
          onSubmit={() =>
            handleStatusUpdate(CompanyStatus.Inactive, deactivateItem.companyId)
          }
        />
      )}
      <AdminHOC
        title="Company List"
        //@ts-ignore
        backgroundColor="#f5f5f5"
        filterComponent={
          <SortSelect
            activeCompanySortOption={activeCompanySortOption}
            setActiveCompanySortOption={setActiveCompanySortOption}/>
        }
      >
        {error && (
          <Error
            message={error}
            close={() => {
              setError(null);
            }}
          />
        )}
        <CompanyListView
          companiesList={filteredCompanies}
          companies={companies}
          handleUpdateSubscriptionClick={handleUpdateSubscriptionClick}
          getServices={getServices}
          getCategories={getCategories}
          getMemberships={getMemberships}
          getCertifications={getCertifications}
          isPrivilegedUser={isPrivilegedUser}
          isUserAdmin={isUserAdmin}
          isLoading={isLoading}
          setApproveItem={setApproveItem}
          setDeactivateItem={setDeactivateItem}
          openSubscriptionTypeModal={openSubscriptionTypeModal}
          handleCancelSubscriptionClick={cancelSubscription}
        />
        {(isPrivilegedUser || (!isPrivilegedUser && !companies.length)) && (
          <Link to="/company" className={s.linkButton}>
            Add new company
          </Link>
        )}
        {isModalOpen && companyToUpdate && (
          <UpdateMembershipModal
            company={companyToUpdate}
            onClose={() => setIsModalOpen(false)}
            refetchCompanies={fetchCompanies}
          />
        )}
        {isUserSubscriptionModalOpen &&
          <SubscriptionPopup
            companyId={companySubscriptionToUpdate}
            onClose={() => setIsUserSubscriptionModalOpen(false)}
            refetchCompanies={fetchCompanies}
          />}
      </AdminHOC>
    </>
  );
};

export default CompanyList;
