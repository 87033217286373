import React from 'react';
import {useLocation} from "react-router-dom";

// @ts-ignore
import Logo from '../../../img/logo.svg';

import s from './CheckoutConfirmPage.module.css';
import {Footer} from "../../UI/Footer";

const BASE_PREMIUM_LOCATIONS = 1;

export const CheckoutConfirmPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('paymentStatus')
  const companyId = queryParams.get('companyId')
  const locationsNumber = queryParams.get('locationsNumber')
  const titleText = paymentStatus && paymentStatus === 'success' ? "Thanks for subscribing" : 'Subscription failed'
  const text = paymentStatus && paymentStatus === 'success' ?
    `Your subscription is now active for company with id (${companyId}) and has the following number of premium 
    locations - ${locationsNumber ? +locationsNumber + BASE_PREMIUM_LOCATIONS : 0}`:
    "Something went wrong with the payment"

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.logoContainer}>
          <img src={Logo} alt="logo"/>
        </div>
      </div>
      <div className={s.popup}>
        <h1>{titleText}</h1>
        <h3>{text}</h3>
      </div>
      <Footer fullWidth />
    </div>
  )
}
