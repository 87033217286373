import React from 'react';
import s from "./index.module.css";
import {Company, CompanyStatus, SubscriptionType} from "../../../../../interfaces/company";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {LocationWithCompanyId, UpdateLocationStatusParams} from "../../useLocationListLogic";
import MapContainer from "../../../../UI/MapContainer/MapContainer";
import {LabelValue} from "../LabelValue";

type LocationViewPropsT = {
  location: LocationWithCompanyId;
  isUserAdmin: boolean;
  isPrivilegedUser: boolean;
  companies: Company[];
  companyId: undefined | string;
  canUpdate: (loc: LocationWithCompanyId) => boolean;
  updateLocationMembership: (params: UpdateLocationStatusParams) => void;
  getLocationStatusUpdateParams: (loc: LocationWithCompanyId) => UpdateLocationStatusParams
}

export const LocationView = (
  {
    location,
    isUserAdmin,
    isPrivilegedUser,
    companies,
    companyId,
    canUpdate,
    updateLocationMembership,
    getLocationStatusUpdateParams,
  }: LocationViewPropsT) => {
  return (
    <div className={s.locationCard}>
      <div>
        <span>{location.locationName}</span>
        <div style={{marginBottom: 6}} className="flex-row">
          <div className="flex-column" style={{marginRight: 24}}>
            <LabelValue label="Address" value={location.locationAddress} />
            <LabelValue label="City" value={location.locationCity} />
            <LabelValue label="Province" value={location.locationProvince} />
            <LabelValue label="Postal code" value={location.locationPostalcode} />
            <LabelValue label="Postal code" value={location.locationPostalcode} />
          </div>
          <div className="flex-column">
            <LabelValue label="Country" value={location.locationCountry} />
            <LabelValue label="Subscription" value={location.locationSubscriptionType} />
          </div>
        </div>
        {location.companyContacts && location.companyContacts.length ? (
          <>
            <span>Contacts</span>
            <div className="flex-row">
              {location.companyContacts.map((el, index) => (
                <div className="flex-column" key={el.id}>
                  <LabelValue label={`Name (#${index + 1})`} value={el.name} />
                  <LabelValue label={`Email (#${index + 1})`} value={el.email} />
                  <LabelValue label={`Phone (#${index + 1})`} value={el.phone} />
                </div>
              ))}
            </div>
          </>
        ): null}
        {(isUserAdmin ||
          !isPrivilegedUser ||
          (isPrivilegedUser &&
            companies.find((company) => company.companyId === location.companyId)
              ?.companyStatus === CompanyStatus.Pending)) && (
          <Link
            className={s.editLink}
            to={`/location/${companyId || location.companyId}/${location.locationId}`}
          >
            Edit Location
          </Link>
        )}
        <Button
          className={s.updateMembershipButton}
          disabled={!canUpdate(location)}
          onClick={() => updateLocationMembership(getLocationStatusUpdateParams(location))}
        >
          {location.locationSubscriptionType === SubscriptionType.Premium ?
            'Downgrade to standard' : 'Update to premium'}
        </Button>
      </div>
      <div>
        <MapContainer
          //@ts-ignore
          markerCoord={{
            lat: parseFloat(location.locationLatitude),
            lng: parseFloat(location.locationLongitude),
          }}
        />
      </div>
    </div>
  )
}
