import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";

import { CompanyInfo } from "./components/Pages/CompanyInfo/CompanyInfo";
import FormLocation from "./components/Pages/LocationInfo";
import { SignIn } from "./components/Pages/SignIn/SignIn";
import { NotFound } from "./components/Pages/NotFound/NotFound";
import CompanyList from "./components/Pages/CompanyList/CompanyList";
import LocationsList from "./components/Pages/LocationsList/LocationsList";
import AdditionalInfo from "./components/Pages/AdditionalInfo/AdditionalInfo";
import { UserRole } from "./interfaces/user";
import { useSelector} from 'react-redux'
import {Banner} from "./components/UI/Banner";
import {CheckoutConfirmPage} from "./components/Pages/CheckoutConfirmPage";
import {RootState} from "./store";

const App = () => {
  const role = useSelector(({ session }) => session.role);
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const banners = useSelector((state:RootState) => state.banner.banners)

  const PrivateRoute = ({ children }: { children?: React.ReactNode }) =>
    isPrivilegedUser ? <>{children}</> : <Navigate to="/company-list" />;

  return (
    <Layout
      style={{
        minHeight: "100vh",
        overflowX: 'hidden'
      }}
    >
      ${banners.map((banner, index) =>
        <Banner key={banner.id} banner={banner} top={index * 55} />)}

      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<CompanyList />} />
        <Route path="/checkout" element={<CheckoutConfirmPage/>} />
        <Route path="/company-list" element={<CompanyList />} />
        <Route path="/location-list" element={<LocationsList />} />
        <Route
          path="/location-list/:companyId"
          element={
            <LocationsList
              //@ts-ignore
              isAnyCompanyChosen={true}
            />
          }
        />
        <Route path="/company" element={<CompanyInfo />} />
        <Route path="/company/:companyId" element={<CompanyInfo />} />
        <Route path="/location" element={<FormLocation />} />
        <Route
          path="/additional-info"
          element={
            <PrivateRoute>
              <AdditionalInfo />
            </PrivateRoute>
          }
        />
        <Route path="/location/:companyId" element={<FormLocation />} />
        <Route
          path="/location/:companyId/:locationId"
          element={<FormLocation />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};
export default App;
