import { useEffect, useState } from "react";

import s from './InputField.module.css';

interface Props {
  name: string;
  pattern?: string; 
  required?: boolean;
  value: string | number;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  min?: number;
  max?: number;
  errMessage?: string;
  step?: string;
  containerClassName?:string;
}

const InputField = ({
  name,
  pattern,
  required = false,
  value,
  className,
  onChange,
  type = "text",
  min,
  max,
  errMessage = "",
  step = "0",
  containerClassName,
}: Props) => {
  const [validErr, setValidErr] = useState("");

  useEffect(() => {
    if (value) {
      if (
        (min !== undefined && parseFloat(value as string) < min) ||
        (max !== undefined && parseFloat(value as string) > max)
      ) {
        setValidErr(errMessage);
      } else {
        if (validErr) {
          setValidErr("");
        }
      }
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <>
      <div className={containerClassName} style={{ position: "relative" }}>
        <p>{name}:</p>
        <input
          step={step}
          type={type}
          value={value}
          className={className}
          onChange={onChange}
          min={min}
          max={max}
          required={required}
          pattern={pattern}
        />
        {validErr && <span className={s.errLabel}>{validErr}</span>}
      </div>
    </>
  );
};

export default InputField;

