import {useCallback, useState} from "react";


export const useConfirmationPopupLogic = () => {
  const [message, setMessage] = useState('');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [onSubmit, setOnSubmit] = useState<() => Promise<void>>(async () => {});

  const onCancel = useCallback(() => {
    setShowConfirmationPopup(false)
    setMessage('');
    setOnSubmit(async () => {});
  }, [])

  const handleSubmit = useCallback(async () => {
    setShowConfirmationPopup(false);
    setMessage('')
    await onSubmit();
    setOnSubmit(async () => {});
  }, [onSubmit])

  return {
    message,
    setMessage,
    showConfirmationPopup,
    setShowConfirmationPopup,
    onCancel,
    handleSubmit,
    setOnSubmit
  }

}
