import React, {ReactElement, ChangeEvent} from 'react';

import s from './index.module.css';

type SelectOption = {
  value: number;
  key: number;
  label: string;
}

type SelectProps = {
  value: string | number;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: SelectOption[];
  children: ReactElement
}

export const Select = ({
   value,
   onChange,
   options,
   children
}:SelectProps) => {
  return (
    <select value={value} onChange={onChange} className={s.selectInput}>
      {children}
      {options.map((item) => {
        return (
          <option value={item.value} key={item.key}>
            {item.label}
          </option>
        );
      })}
    </select>
  )
}
