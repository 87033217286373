import {ChangeEvent, useCallback, useState} from "react";

export const useSubscriptionPopupLogic = () => {
  const [step, setStep] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState(0);
  const basePrice = 1200;
  const pricePerLocation = 100;
  const handleStepChange = (step: number) => {
    setStep(step)
  }

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedLocations(+e.target.value)
  }, []);

  return {
    step,
    handleInputChange,
    selectedLocations,
    basePrice,
    pricePerLocation,
    handleStepChange
  }
}
