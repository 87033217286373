import React from 'react';

type LabelValuePropsT = {
  label: string;
  value: string | number;
}

export const LabelValue = (
  {
    label,
    value
  }: LabelValuePropsT) => {
  return (
    <div>
      <span>{label}:</span>
      <span>{value}</span>
    </div>
  )
}
