import s from "./SignIn.module.css";
import {PhoneInputField} from "../../UI/Input/PhoneInputField";
import {useSignInLogic} from "./useSignInLogic";
import {Footer} from "../../UI/Footer";

const AUTH_API_VERSION = 'v2.1.0'

export const SignIn = () => {
  const {
    phone,
    error,
    code,
    phoneError,
    setPhoneError,
    setPhone,
    setCode,
    submitHandler,
    tryAgainHandler,
    getCodeByNumber,
  } = useSignInLogic();

  return (
    <>
      {error ? (
      <div className={s.container}>
        <h1>Error</h1>
        <p>{error}</p>
        <div className={s.button} onClick={tryAgainHandler}>
          Try again
        </div>
      </div>
      ) : (
      <form className={s.container} onSubmit={submitHandler}>
        <h1>Sign in</h1>
        {!code.codeRecieved ? (
          <PhoneInputField
            value={phone}
            onChange={setPhone}
            setError={setPhoneError}
            error={phoneError}
          />
        ) : (
          <input
            value={code.value}
            placeholder="code"
            type="number"
            onChange={(e) => {
              setCode({...code, value: e.target.value});
            }}
          />
        )}
        <button type="submit" className={s.button}>
          {code.codeRecieved ? "Log in" : "Get code"}
        </button>
        {code.codeRecieved && (
          <div className={s.newCode} onClick={getCodeByNumber}>
            send code again
          </div>
        )}
        <label>system</label>
        <span>Auth API: {AUTH_API_VERSION}</span>
      </form>
      )}
      <Footer fullWidth/>
    </>
  );
};
