import {Link} from "react-router-dom";
import s from "./LocationsList.module.css";
import {Error} from "../../UI/Error/Error";
import {AdminHOC} from "../../HOCs/AdminHOC/AdminHOC";
import {useLocationListLogic} from "./useLocationListLogic";
import {LocationView} from "./components/LocationView";


const LocationsList = () => {
  const {
    companies,
    locations,
    isUserAdmin,
    isPrivilegedUser,
    error,
    setError,
    companyId,
    updateLocationMembership,
    getLocationStatusUpdateParams,
    canUpdate
  } = useLocationListLogic();

  return (
    //@ts-ignore
    <AdminHOC title="Locations List" backgroundColor="#f5f5f5">
      {error && (
        <Error
          message={error}
          close={() => {
            setError(null);
          }}
        />
      )}
      {locations.map((loc) =>
        <LocationView
          key={`location-${loc.locationId}`}
          location={loc}
          isUserAdmin={isUserAdmin}
          isPrivilegedUser={isPrivilegedUser}
          companies={companies}
          companyId={companyId}
          canUpdate={canUpdate}
          updateLocationMembership={updateLocationMembership}
          getLocationStatusUpdateParams={getLocationStatusUpdateParams}
        />)}
      <Link to="/location" className={s.linkButton}>
        Add new location
      </Link>
    </AdminHOC>
  );
};

export default LocationsList;
