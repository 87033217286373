import React, {ChangeEvent, useCallback, useState, useEffect} from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import s from "../CompanyList.module.css";
import { useCompanyApi } from "../../../../hooks/useCompanyApi";
import {
  Company,
  SubscriptionType,
  SubscriptionTypeMap,
} from "../../../../interfaces/company";
import InputField from "../../../UI/Input/InputField";

interface Props {
  onClose: () => void;
  company: Company;
  refetchCompanies: () => void;
}

const UpdateMembershipModal = ({
  onClose,
  company,
  refetchCompanies,
}: Props) => {
  const [seatsAllocated, setAllocatedSeats] = useState(0);
  const { patchCompany, updateCompanySeats } = useCompanyApi();

  const onOutsideClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const element = event.target;
    //@ts-ignore
    if (element.classList.contains("Modal")) {
      onClose();
    }
  };

  const onUpdate = useCallback(async () => {
    const updateFields = {
      status: seatsAllocated > 0 ?
        SubscriptionTypeMap[SubscriptionType.Premium] :
        SubscriptionTypeMap[SubscriptionType.Standard]
    }
    Promise.all([patchCompany(company.companyId, updateFields),
      updateCompanySeats(seatsAllocated, company.companyId)]).then(() => refetchCompanies());
    onClose();
    //eslint-disable-next-line
  }, [company, seatsAllocated]);

  const onSeatsNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= 0 && ( +e.target.value % 1 === 0 )) {
      setAllocatedSeats(+e.target.value)
    }
  },[]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = "15px";
  });

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.marginRight = "0px";
    }
  }, [])


  return (
    <div
      className={`${s.modalWrapper} Modal`}
      onClick={(event) => onOutsideClick(event)}
    >
      <div className={s.modalContent}>
        <CloseCircleFilled
          className={s.closeButton}
          onClick={() => onClose()}
        />
        <h1>Update Membership</h1>
        <InputField className={s.modalInput} name={'Number of premium locations'} value={seatsAllocated} onChange={onSeatsNumberChange} type='number'/>
        <div className={`${s.changeStatusBtns} ${s.modalBtns}`}>
          <button onClick={onUpdate}>Update</button>
          <button onClick={() => onClose()}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default UpdateMembershipModal;
