import React from "react";
import s from './SubscriptionPopup.module.css';
import {CloseCircleFilled} from "@ant-design/icons";
import StripeContainer from "../Stripe/StripeContainer";
import {useSubscriptionPopupLogic} from "./useSubscriptionPopupLogic";

type SubscriptionPopupProps = {
  onClose: () => void;
  companyId: number;
  refetchCompanies: () => void;
}


export const SubscriptionPopup = ({onClose, companyId, refetchCompanies}: SubscriptionPopupProps) => {
  const {
    step,
    selectedLocations,
    handleInputChange,
    basePrice,
    pricePerLocation,
    handleStepChange,
  } = useSubscriptionPopupLogic();

  return (
    <div className='modalWrapper Modal'>
      <div className={s.modalContent}>
        <CloseCircleFilled
          className={s.closeButton}
          onClick={() => onClose()}
        />
        {step === 0 ? <div className={s.modalForm}>
          <h1 className={s.modalFormHeading}>Update Company Membership</h1>
          <p className={s.modalFormTitle}>Premium Listing Upgrade</p>
          <p className={s.modalFormSubTitle}>$1,200 per year includes 1 location</p>
          <p className={s.modalFormSubTitle}>$100 per year for each additional location</p>
          <p style={{marginTop: 26}} className={s.modalFormTitle}>Additional Premium Locations</p>
          <p className={s.modalFormSubTitle}>Add additional location:
            <input
              onFocus={(e) => e.target.select()}
              className={s.input}
              value={selectedLocations}
              min={0} type="number"
              max={99}
              onChange={handleInputChange}
            />
          </p>

          <p className={s.modalFormBoldTitle}>Subscription Summary</p>
          <p className={s.modalFormSubTitle}>$1,200 first location</p>
          <p className={s.modalFormSubTitle}>${(selectedLocations * pricePerLocation).toLocaleString()} each additional
            Location</p>
          <p className={s.modalFormSubTitleBold}>
            <span style={{width: 80}}>Locations:</span> {selectedLocations + 1}
          </p>
          <p className={s.modalFormSubTitleBold}>
            <span
              style={{width: 80}}>Total:</span> ${(basePrice + (selectedLocations * pricePerLocation)).toLocaleString()}/year
          </p>

          <div className={`${s.changeStatusBtns} ${s.modalBtns}`}>
            <button onClick={() => onClose()}>Cancel</button>
            <button style={{width: 200}} onClick={() => handleStepChange(1)}>Continue To Payment</button>
          </div>
        </div> : <div className={s.modalForm}>
          <h1 className={s.modalFormHeading}>Payment</h1>
          <p className={s.modalFormBoldTitle}>Subscription Summary</p>
          <p className={s.modalFormSubTitle}>
            $1,200 first location
            <span className={s.modalFormSubTitleCount}>1x</span>
          </p>
          <p className={s.modalFormSubTitle}>
            ${selectedLocations * pricePerLocation} additional locations
            <span className={s.modalFormSubTitleCount}>{selectedLocations}x</span>
          </p>
          <p className={s.modalFormSubTitleBold}>
            <span style={{width: 80}}>Locations:</span> {selectedLocations + 1}
          </p>
          <p className={s.modalFormSubTitleBold}>
            <span
              style={{width: 80}}>Total:</span> ${(basePrice + (selectedLocations * pricePerLocation)).toLocaleString()}/year
          </p>

          <div className={s.cardModal}>
            <StripeContainer
              companyId={companyId}
              locationsNumber={selectedLocations}
              onClose={onClose}
              refetchCompanies={refetchCompanies}
            />
          </div>

          <div className={`${s.changeStatusBtns} ${s.modalBtns}`}>
            <button style={{background: 'rgb(226, 62, 62)'}} onClick={() => handleStepChange(0)}>Back</button>
          </div>
        </div>}

      </div>
    </div>
  )
};
