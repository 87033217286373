//@ts-nocheck
import { useState, useCallback, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'

import { useParams } from "react-router-dom";
import { useCompanyApi } from "../../../../hooks/useCompanyApi";
import { useLocationApi } from "../../../../hooks/useLocationApi";
import { INITIAL_CONTACTS, INITIAL_LOCATION } from "../constants";
import { v4 as uuidv4 } from "uuid";
import { UserRole } from "../../../../interfaces/user";
import { CompanyStatus } from "../../../../interfaces/company";
import {RootState} from "../../../../store";
import {addBanner} from "../../../../store/banner/bannerSlice";

export const useLocationLogic = () => {
  const { host, role } = useSelector((state: RootState) => state.session);
  const { company } = useSelector((state: RootState) => state.company);
  const dispatch = useDispatch();
  const isUserAdmin = role === UserRole.Admin;
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const { companyId, locationId } = useParams();
  const [currentCompanyId, setCurrentCompanyId] = useState(company? company.companyId: companyId);
  const { error, setError, getCompanies, getAdminCompanies } = useCompanyApi();
  const {
    error: locationError,
    setError: setLocationError,
    saveLocation,
    getLocation,
    updateLocation,
    success,
    setSuccess,
    getLocationAdmin,
  } = useLocationApi();
  const [companies, setCompanies] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [location, setLocation] = useState(INITIAL_LOCATION);
  const [contacts, setContacts] = useState([...INITIAL_CONTACTS]);

  const clear = () => {
    setLocation(INITIAL_LOCATION);
    setContacts([...INITIAL_CONTACTS]);
  };

  useEffect(() => {
    if(success) {
      dispatch(addBanner({bannerMessage: success, bannerStatus: 'success'}))
    }
  }, [success, dispatch])

  const getCompaniesHandler = useCallback(async () => {
    try {
      let res;
      if (isPrivilegedUser) {
        res = await getAdminCompanies();
      } else {
        res = await getCompanies();
      }
      const fetchedCompanies = res.companiesActive
        .filter(
          (company) =>
            isUserAdmin || company.companyStatus === CompanyStatus.Pending
        )
        .map((company) => {
          return { id: company.companyId, name: company.companyName };
        });
      setCompanies(fetchedCompanies);
    } catch (err) {
      console.log(err.message);
    }
    //eslint-disable-next-line
  }, [isPrivilegedUser, isUserAdmin]);

  const changeContactHandler = (e, localId, field) => {
    const copy = contacts.concat();
    const index = copy.findIndex((contact) => contact.localId === localId);
    copy[index][field] = e.target.value;
    setContacts(copy);
  };

  const fetchLocation = async (data) => {
    try {
      let res;
      if (isPrivilegedUser) {
        res = await getLocationAdmin(data);
      } else {
        res = await getLocation(data);
      }
      const copy = JSON.parse(JSON.stringify(location));
      Object.keys(res).forEach((key) => {
        if (Object.keys(location).includes(key)) {
          copy[key].value = res[key];
        }
      });
      copy.companyId.value = +companyId;
      setLocation(copy);
      setShowChart(true);
      if (res.companyContacts.length) {
        setContacts(
          res.companyContacts.map((el) => {
            return { ...el, localId: uuidv4() };
          })
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const createLocation = async (data) => {
    try {
      await saveLocation(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      {
        id: null,
        localId: uuidv4(),
        name: "",
        email: "",
        phone: "",
      },
    ]);
  };

  const updateLocationHandler = async (data) => {
    try {
      await updateLocation(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteContact = (id) => {
    const copy = contacts.concat();
    const index = copy.findIndex((el) => el.localId === id);
    copy.splice(index, 1);
    setContacts(copy);
  };

  const isValid = () => {
    let valid = true;
    Object.keys(location).forEach((key) => {
      if (key !== "locationId") {
        valid = !!location[key].value ? valid : false;
      }
    });
    return valid;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isValid()) {
      const data = {
        contacts: contacts
          .filter((contact) => contact.phone || contact.email)
          .map((contact) => {
            return {
              id: contact.id,
              email: contact.email,
              phone: contact.phone,
              name: contact.name,
            };
          }),
        location: {} as Record<string, any>,
      };
      Object.keys(location).forEach((key) => {
        data.location[key] = location[key].value;
      });
      if (data.location.locationId) {
        updateLocationHandler(data);
      } else {
        createLocation(data);
      }
    } else {
      const copy = JSON.parse(JSON.stringify(location));
      Object.keys(copy).forEach((key) => {
        if (!copy[key].value) copy[key].valid = false;
      });
      setLocation(copy);
    }
  };

  const selectHandler = (e) => {
    const copy = JSON.parse(JSON.stringify(location));
    copy.companyId.value = e.target.value;
    setCurrentCompanyId(+e.target.value)
    copy.companyId.valid = true;
    setLocation(copy);
  };

  const changeHandler = useCallback(
    (e, key) => {
      setLocation((oldLocation) => {
        const copy = JSON.parse(JSON.stringify(oldLocation));
        copy[key].value = e.target.value;
        copy[key].valid = true;
        return copy;
      });
    },
    [setLocation]
  );

  useEffect(() => {
    changeHandler({ target: { value: companyId || host } }, "companyId");
    // eslint-disable-next-line
  }, [host, companyId]);

  useEffect(() => {
    getCompaniesHandler().then();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(companies){
      if (companies.length > 0 && !currentCompanyId) {
        setCurrentCompanyId(companies[0].id);
        setLocation(loc => ({...loc, companyId: {...loc.companyId, value: companies[0].id}}))
      }
    }
  }, [companies, location.companyId, currentCompanyId])

  useEffect(() => {
    if (locationId && companyId) {
      fetchLocation({ companyId, locationId }).then();
    } else {
      clear();
      setShowChart(true);
    }
    // eslint-disable-next-line
  }, [companyId, locationId]);

  useEffect(() => {
    if (companyId) {
      setLocation((oldLocation) => ({
        ...oldLocation,
        companyId: { ...oldLocation.companyId, value: companyId },
      }));
    }else if(company) {
      setLocation((oldLocation) => ({
        ...oldLocation,
        companyId: { ...oldLocation.companyId, value: company.companyId },
      }));
    }
  }, [companyId, company]);

  const changeCoordValues = useCallback((lat, long) => {
    setLocation({
      ...location,
      locationLatitude: { ...location.locationLatitude, value: lat },
      locationLongitude: { ...location.locationLongitude, value: long },
    });
  }, [location]);

  return {
    location,
    setLocation,
    currentCompanyId,
    companyId,
    locationId,
    error,
    setError,
    locationError,
    setLocationError,
    setSuccess,
    submitHandler,
    selectHandler,
    companies,
    showChart,
    changeHandler,
    contacts,
    setContacts,
    isValid,
    success,
    changeContactHandler,
    addContact,
    deleteContact,
    changeCoordValues,
  };
};
