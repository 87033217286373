import s from "./index.module.css";
import CompanyNoLogo from "../../../../../img/leaseLocatorLogo.jpg";
import {Company, CompanyStatus, SubscriptionType} from "../../../../../interfaces/company";
import {Button} from "antd";
import {LabelValue} from "../../../../UI/LabelValue";
import {getCompanyStatusString} from "../../../../../helpers/getCompanyStatusString";
import {getCompanyStatusColor} from "../../../../../helpers/getCompanyStatusColor";
import {Link} from "react-router-dom";
import {setCompany} from "../../../../../store/company/companySlice";
import {AdditionalInfoData} from "../../../../../interfaces/additionalInfo";
import {useDispatch} from "react-redux";

type CompanyListViewProps = {
  companiesList: Company[];
  companies: Company[]
  handleUpdateSubscriptionClick: (id: number) => void;
  getServices: (services: AdditionalInfoData[]) => string;
  getCategories: (categories: AdditionalInfoData[]) => string;
  getMemberships: (memberships: AdditionalInfoData[]) => string;
  getCertifications: (certifications: AdditionalInfoData[]) => string;
  isPrivilegedUser: boolean;
  isUserAdmin: boolean;
  isLoading: boolean | number;
  setApproveItem: (company: Company | null) => void;
  setDeactivateItem: (company: Company | null) => void;
  openSubscriptionTypeModal: (company: Company) => void;
  handleCancelSubscriptionClick: (companyId: number) => void;
}

export const CompanyListView =
  ({
     companiesList,
     companies,
     handleUpdateSubscriptionClick,
     getServices,
     getCategories,
     getMemberships,
     getCertifications,
     isPrivilegedUser,
     isUserAdmin,
     isLoading,
     setApproveItem,
     setDeactivateItem,
     openSubscriptionTypeModal,
     handleCancelSubscriptionClick
  }: CompanyListViewProps) => {
    const dispatch = useDispatch();
    return (
      <>
        {companiesList.map((company) =>
            <div
              className={`${s.companyCard} ${
                company.companyStatus === "INACTIVE"
                  ? s.premium
                  : ""
              }`}
              key={`company-${company.companyId}`}
            >
              <div className={s.companyView}>
                <img
                  className={s.companyLogo}
                  src={company.companyLogo ? company.companyLogo+`?timestamp=${new Date().getTime()}` : CompanyNoLogo}
                  alt="company logo"
                />
                <div style={{width: '100%'}}>
                  <div className={s.companySubscriptionBlock}>
                    <h1 className={s.companySubscriptionTitle}>{company.companySubscriptionType} Listing</h1>
                    <div className={s.companySubscriptionLocations}>
                      <div className={s.companySubscriptionLocationsList}>
                        <p>Premium Locations</p>
                        <div className={s.companySubscriptionLabelValues}>
                          <p><span>Seats Purchased:</span><span>{company.seats}</span></p>
                          <p><span>Seats Allocated:</span><span>{company.companyLocations.filter(location => location.locationSubscriptionType === SubscriptionType.Premium).length}</span></p>
                        </div>
                      </div>
                      <Button onClick={() => handleUpdateSubscriptionClick(company.companyId)}>Purchase Premium Locations</Button>
                      {company.subscriptionId ? <Button style={{marginLeft: 12}} onClick={() => handleCancelSubscriptionClick(company.companyId)}>Cancel Subscription</Button> : null}
                    </div>
                  </div>
                  <LabelValue label="Name:" value={company.companyName} />
                  <LabelValue
                    label="Description:"
                    value={company.companyDescription}
                  />
                  <LabelValue
                    label="Website:"
                    value={company.companyWebsite}
                    isLink
                  />
                  <LabelValue
                    label="Linkedin:"
                    value={company.companyLinkedin}
                    isLink
                  />
                  <LabelValue
                    label="Services:"
                    value={getServices(company.companyServices)}
                  />
                  <LabelValue
                    label="Categories:"
                    value={getCategories(company.companyCategory)}
                  />
                  <LabelValue
                    label="Memberships:"
                    value={getMemberships(company.companyMemberships)}
                  />
                  <LabelValue
                    label="Certifications:"
                    value={getCertifications(company.companyCertifications)}
                  />
                  <LabelValue
                    label="Status:"
                    value={getCompanyStatusString(company.companyStatus)}
                    color={getCompanyStatusColor(company.companyStatus)}
                  />
                  <LabelValue
                    label="Subscription type:"
                    value={company.companySubscriptionType}
                    capitalize
                  />
                  <LabelValue
                    label="Locations count:"
                    value={`${company.companyLocationCount}`}
                    capitalize
                  />
                  {isPrivilegedUser && <LabelValue label="Number of premium locations:" value={company.seats} />}
                </div>
                {(isUserAdmin || (!isUserAdmin && !companies.length)) && (
                  <div className={s.changeStatusBtns}>
                    <button
                      className={
                        company.companyStatus === CompanyStatus.Active || isLoading
                          ? s.disabledBtn
                          : ""
                      }
                      onClick={() => setApproveItem(company)}
                      disabled={
                        company.companyStatus === CompanyStatus.Active ||
                        (isLoading as boolean)
                      }
                    >
                      Approve
                    </button>
                    <button
                      className={
                        company.companyStatus === CompanyStatus.Inactive || isLoading
                          ? s.disabledBtn
                          : ""
                      }
                      onClick={() => setDeactivateItem(company)}
                      disabled={
                        company.companyStatus === CompanyStatus.Inactive ||
                        (isLoading as boolean)
                      }
                    >
                      Deactivate
                    </button>
                    <button
                      className={s.updateMembershipBtn}
                      onClick={() => openSubscriptionTypeModal(company)}
                    >
                      Update Membership
                    </button>
                  </div>
                )}
              </div>
              <div>
                {(isUserAdmin ||
                  !isPrivilegedUser ||
                  company.companyStatus === CompanyStatus.Pending) && (
                  <>
                    <Link
                      onClick={() => dispatch(setCompany(company))}
                      to={`/company/${company.companyId}`}
                    >
                      Edit Company
                    </Link>
                    {company.companyLocationCount !== 0 ? (
                      <Link
                        onClick={() => dispatch(setCompany(company))}
                        to={`/location-list/${company.companyId}`}
                      >
                        Locations
                      </Link>
                    ) : (
                      <Link to={`/location/${company.companyId}`} onClick={() => dispatch(setCompany(company))}>
                        Add Location
                      </Link>
                    )}
                  </>
                )}
              </div>
              {isLoading === company.companyId && (
                <div className={s.loaderWrapper}>
                  <div className={s.loader} />
                </div>
              )}
            </div>
          )};
      </>
    )
}
