import { v4 as uuidv4 } from "uuid";

export const INITIAL_LOCATION = {
  locationId: {
    name: "locationId",
    value: "",
    valid: true,
  },
  companyId: {
    name: "Company",
    value: "",
    valid: true,
  },

  locationName: {
    name: "Location Name",
    value: "",
    valid: true,
  },
  locationAddress: {
    name: "Address",
    value: "",
    valid: true,
  },
  locationCountry: {
    name: "Country",
    value: "Canada",
    valid: true,
  },
  locationProvince: {
    name: "Province",
    value: "Alberta",
    valid: true,
  },
  locationCity: {
    name: "City",
    value: "Airdrie",
    valid: true,
  },
  locationPostalcode: {
    name: "Postal Code",
    value: "",
    valid: true,
  },

  locationCode: {
    name: "Global Code",
    value: "000",
    valid: true,
  },
  locationLatitude: {
    name: "Latitude",
    value: 51.1115,
    valid: true,
  },
  locationLongitude: {
    name: "Longitude",
    value: -114.1115,
    valid: true,
  },
};

export const INITIAL_CONTACTS = [
  {
    id: null,
    localId: uuidv4(),
    name: "",
    email: "",
    phone: "",
  },
];
