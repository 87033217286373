import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

export interface BannerInfo {
  bannerStatus: 'info' | 'error' | 'success';
  bannerMessage: string;
  id: string;
}

export interface BannerStore {
  banners: BannerInfo[]
}

const initialState: BannerStore = {
  banners: []
};

export const companySlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    addBanner: (state, action:PayloadAction<Omit<BannerInfo, "id">>) => {
      const banner = {...action.payload, id: uuidv4()}
      if(state.banners.length < 4)
        state.banners = [...state.banners, banner]
    },
    removeBanner: (state, action:PayloadAction<string>) => {
      state.banners = state.banners.filter(banner => banner.id !== action.payload);
    },
  },
})

export const { addBanner, removeBanner } = companySlice.actions

export default companySlice.reducer
