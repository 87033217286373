import s from "./PopUp.module.css";
import { createPortal } from "react-dom";

interface Props {
  children?: React.ReactNode
  onClose: () => void;
  className?: string; 
}

export const PopUp = ({ children, onClose, className }: Props) => {
  const Modal = (
    <div onClick={onClose} className={s.blurDiv}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${s.content} ${className}`}
      >
        {children}
      </div>
    </div>
  );

  return createPortal(Modal, document.getElementById("modal") as HTMLDivElement);
};
