import {useCallback, useEffect, useState} from "react";
import { State, City }  from 'country-state-city';
import { mainAPI } from "../../../../api/configureAxios";


type Cities = Record<string, Record<string, string[]>>;

const useCities = () => {
  const [states, setStates] = useState<Record<string, string>>({});
  const COUNTRIES = ['Canada', 'USA']


    const [cities, setCities] = useState<Cities>({});

    const fetchCities = async () => {
      const { data } = await mainAPI.get("/cities.json", { baseURL: "" });
      setCities(data);
    };

    useEffect(() => {
      fetchCities();
    }, []);

  useEffect(() => {
    const stateMapped:Record<string, string> = {};
    State.getStatesOfCountry("US").forEach(state => {
      stateMapped[state.name] = state.isoCode;
    })
    State.getStatesOfCountry('CA').forEach(state => {
      stateMapped[state.name] = state.isoCode;
    })
    setStates(stateMapped)
  }, []);

  const getSelectedStatesOnCountry = useCallback((countryName: string): string[] => {
    if(countryName === 'Canada')
      return State.getStatesOfCountry("CA").map(state => state.name);
    return State.getStatesOfCountry("US").map(state => state.name);
  },[])

  const getCitiesOfState = useCallback((countryName: string, stateName: string): string[] => {
    const MISSING_ALBERTA_CITIES = ['Clairmont', 'Nisku', 'Wimborne', 'Chauvin']
    if(countryName === 'Canada') {
      const cityArray = City.getCitiesOfState('CA', states[stateName]).map(city => city.name);
      if(stateName === 'Alberta'){
        return [...cityArray, ...MISSING_ALBERTA_CITIES].sort();
      }
      return cityArray
    }

    return City.getCitiesOfState('US', states[stateName]).map(city => city.name);
  },[states])

  return { getSelectedStatesOnCountry, getCitiesOfState, COUNTRIES, cities };
};


export default useCities;
