//@ts-nocheck
import { useCallback, useState } from "react";
import { mainAPI } from "../api/configureAxios";

export const useAdditionalInfoApi = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const changeInfo = useCallback(
    async (payload, activeCategoryName, method) => {
      try {
        const user = localStorage.getItem("user");
        if (!user) {
          throw new Error("Not Authorized");
        }
        const config = {
          headers: { Authorization: `Bearer ${user}` },
        };

        const { data } = await mainAPI[method](
          `admin/${activeCategoryName}`,
          payload,
          config
        );

        if (!data.success) {
          throw new Error(data.message);
        } else {
          setSuccess(data.message);
        }
        return data;
      } catch (err) {
        const message = err.response?.data?.message;
        setError(message || err.message);
        throw new Error(err.message);
      }
    },
    []
  );

  const deleteInfoItem = useCallback(async (activeCategoryName, itemId) => {
    try {
      const user = localStorage.getItem("user");
      if (!user) {
        throw new Error("Not Authorized");
      }
      const config = {
        headers: { Authorization: `Bearer ${user}` },
      };

      const { data } = await mainAPI.delete(
        `admin/${activeCategoryName}/${itemId}`,
        config
      );

      if (!data.success) {
        throw new Error(data.message);
      } else {
        setSuccess(data.message);
      }
      return data;
    } catch (err) {
      const message = err.response?.data?.message;
      setError(message || err.message);
      throw new Error(err.message);
    }
  }, []);

  return {
    error,
    setError,
    success,
    setSuccess,
    changeInfo,
    deleteInfoItem,
  };
};
