import {useCompanyApi} from "../../../hooks/useCompanyApi";
import { useDispatch, useSelector } from "react-redux";
import {RootState} from "../../../store";
import {UserRole} from "../../../interfaces/user";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAdditionalInfoApi} from "../../../hooks/useAdditionalInfoApi";
import {AdditionalInfoData, InfoType, InfoTypeId} from "../../../interfaces/additionalInfo";
import {ActiveItem, AdditionalInfoTreeNode, ParentItem} from "./AdditionalInfo";
import {sortAdditionalInfoByName} from './helper';
import {addBanner} from "../../../store/banner/bannerSlice";

const INITIAL_ADDITIONAL_INFO: Record<InfoType, AdditionalInfoData[]> = {
  categories: [],
  certifications: [],
  memberships: [],
  services: [],
};

export const useAdditionalInfoLogic = () => {
  const dispatch = useDispatch();
  const { getCreateInfo } = useCompanyApi();
  const role = useSelector((state: RootState) => state.session.role);
  const isPrivilegedUser = [UserRole.Admin, UserRole.Support].includes(role);
  const navigate = useNavigate();
  const [additionalInfo, setAdditionalInfo] = useState(INITIAL_ADDITIONAL_INFO);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<ActiveItem>();
  const { error, setError, success, setSuccess, deleteInfoItem, changeInfo } =
    useAdditionalInfoApi();

  useEffect(() => {
    if (!isPrivilegedUser) {
      navigate("/company-list");
    }
  }, [isPrivilegedUser, navigate]);

  useEffect(() => {
    if(success) {
      dispatch(addBanner({bannerMessage: success, bannerStatus: 'success'}))
    }
  }, [success, dispatch])

  const getInfo = async () => {
    try {
      const res = await getCreateInfo();
      setAdditionalInfo(res);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getInfo();
    //eslint-disable-next-line
  }, [success]);

  const convertToNestedArray = (
    items: AdditionalInfoData[],
    idKey: InfoTypeId
  ) => {
    const buildNestedArray = (parentId: number | null = null) => {
      const treeLayer: AdditionalInfoTreeNode[] = items
        .filter((item) => (item.parent_id ?? null) === parentId)
        .map((item) => ({ ...item, children: [] as AdditionalInfoTreeNode[] }));

      treeLayer.sort((a, b) => sortAdditionalInfoByName(a, b));
      treeLayer.forEach((item) => {
        item.children = buildNestedArray(item[idKey]);
      });
      return treeLayer;
    };
    return buildNestedArray();
  };

  const certifications = useMemo(() => {
    return convertToNestedArray(
      additionalInfo.certifications,
      InfoTypeId.Certification
    );
    //eslint-disable-next-line
  }, [additionalInfo.certifications]);

  const categories = useMemo(() => {
    return convertToNestedArray(additionalInfo.categories, InfoTypeId.Category);
    //eslint-disable-next-line
  }, [additionalInfo.categories]);

  const memberships = useMemo(() => {
    return convertToNestedArray(
      additionalInfo.memberships,
      InfoTypeId.Membership
    );
    //eslint-disable-next-line
  }, [additionalInfo.categories]);

  const services = useMemo(() => {
    return convertToNestedArray(additionalInfo.services, InfoTypeId.Service);
    //eslint-disable-next-line
  }, [additionalInfo.categories]);

  const getParents = useCallback(() => {
    if (!activeItem) return [];
    const mapDataByInfoType: Record<InfoType, AdditionalInfoTreeNode[]> = {
      [InfoType.Category]: categories,
      [InfoType.Service]: services,
      [InfoType.Certification]: [],
      [InfoType.Membership]: [],
    };
    const data = mapDataByInfoType[activeItem.category];
    const result: ParentItem[] = [];

    const fillResult = (
      currentData: AdditionalInfoTreeNode[] = data,
      layer = 0
    ) => {
      currentData.sort(sortAdditionalInfoByName);
      currentData.forEach((node) => {
        result.push({
          id: node[activeItem.key] as number,
          name: node.name,
          layer,
        });
        fillResult(node.children, layer + 1);
      });
    };

    fillResult();

    return result;
  }, [activeItem, categories, services]);

  return {
    error,
    success,
    memberships,
    certifications,
    services,
    categories,
    isModalOpen,
    activeItem,
    changeInfo,
    getParents,
    setSuccess,
    setError,
    setIsModalOpen,
    setActiveItem,
    deleteInfoItem,
  }
}
