//@ts-nocheck
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Component } from 'react';

const GOOGLE_API_KEY = 'AIzaSyBK-kj3WQGJzx-3in-ziroqyWB0jb7xYbY';

const containerStyle = {
  position: 'relative',
  width: '250px',
  height: '192px'
}

export class MapContainer extends Component {
  render() {
    return (
      <Map
        onClick={(props, marker, e) => this.props.changeCoordValues && this.props.changeCoordValues(e.latLng.lat(), e.latLng.lng())}
        google={this.props.google}
        zoom={17}
        containerStyle={this.props.containerStyle ? this.props.containerStyle : containerStyle}
        center={this.props.markerCoord || { lat: 0, lng: 0 }}
        initialCenter={this.props.markerCoord || { lat: 0, lng: 0 }}
      >
        <Marker
          name={'Current location'}
          position={this.props.markerCoord && (this.props.markerCoord.lat || this.props.markerCoord.lat) && this.props.markerCoord}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY
})(MapContainer)
