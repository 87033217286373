import axios from 'axios'
import CONFIG from "../config";

function makeApi(baseURL: string) {
  const api = axios.create({ baseURL })

  api.defaults.headers.post['Content-Type'] = 'application/json'
  api.defaults.headers.put['Content-Type'] = 'application/json'
  api.defaults.headers.delete['Content-Type'] = 'application/json'

  api.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  )

  api.interceptors.response.use(
    (response) => response, // return data object
    (error) =>  Promise.reject(error),
  )
  return api
}

export const mainAPI = makeApi(CONFIG.baseUrl)
export const authAPI = makeApi(CONFIG.authUrl)
