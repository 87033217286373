import React from 'react';
import s from "./index.module.css";
import {CompanySortOption, CompanySortOptionLabel} from "../../useCompanyListLogic";

type SortSelectProps = {
  activeCompanySortOption: CompanySortOption;
  setActiveCompanySortOption: (option: CompanySortOption) => void;
}

export const SortSelect = (
  {
    activeCompanySortOption,
    setActiveCompanySortOption
  }: SortSelectProps) => {

  return (
    <div className={s.statusFilter}>
      <p>Sort by:</p>
      <select
        value={activeCompanySortOption}
        onChange={(e) => {
          setActiveCompanySortOption(e.target.value as CompanySortOption);
        }}
      >
        {Object.values(CompanySortOption).map((item) => (
          <option value={item} key={item}>
            {CompanySortOptionLabel[item]}
          </option>
        ))}
      </select>
    </div>
  )
}
