import {useNavigate} from "react-router-dom";
import {useAuthApi} from "../../../hooks/useAuthApi";
import {useState} from "react";
import {clearStorage, setStoragePhone, setStorageUser} from "../../../helpers/storage";
import {v4 as uuidv4} from "uuid";

const deviceId = uuidv4();

export const useSignInLogic = () => {
  const history = useNavigate();
  const { error, setError, getCode, verifyCode } = useAuthApi();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState({
    codeRecieved: false,
    value: "",
  });
  const [phoneError, setPhoneError] = useState("");

  const getCodeByNumber = async () => {
    if (phone.length < 10) {
      setPhoneError("Please enter valid phone number");
      return;
    }
    try {
      await getCode({ phone, deviceId });
      setCode({ ...code, codeRecieved: true });
    } catch (err) {
      //@ts-ignore
      console.log(err.message);
    }
  };

  const signIn = async () => {
    clearStorage()
    try {
      const res = await verifyCode({ phone, deviceId, code: code.value });
      setStorageUser(res.token)
      setStoragePhone(phone)
      history("/company-list");
    } catch (err) {
      //@ts-ignore
      console.log(err.message);
    }
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (!code.codeRecieved) {
      getCodeByNumber();
    } else {
      signIn();
    }
  };

  const tryAgainHandler = () => {
    setPhone("");
    setCode({ codeRecieved: false, value: "" });
    setError(null);
  };

  return {
    phone,
    error,
    code,
    phoneError,
    setPhone,
    setPhoneError,
    setCode,
    submitHandler,
    tryAgainHandler,
    getCodeByNumber,
  }
}
