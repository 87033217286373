import React from 'react';
import {Layout} from "antd";

import s from "./index.module.css";
//@ts-ignore
import Logo from "../../../../../img/logo.svg";
import {NavLink} from "react-router-dom";
import {cx} from "linaria";
import {CompanyStatus, CompanyStatusLabel} from "../../../../../interfaces/company";
import {useAdminHOCLogic} from "../../useAdminHOCLogic";
const { Sider } = Layout;


export const Sidebar = () => {
  const {
    phoneNumber,
    status,
    roleCharacter,
    handleLinkClick,
    handleLogout,
    companyPerStatusCount,
    isPrivilegedUser
  } = useAdminHOCLogic()

  return(
    <Sider className={s.sidebar}>
      <div>
        <div className={s.logoContainer}>
          <img src={Logo} alt="logo" />
        </div>
      </div>

      <div className={s.menu}>
        {isPrivilegedUser ? (
          <div className={s.companyLinkWrapper}>
            <NavLink
              onClick={handleLinkClick}
              to="/company-list"
              className={({isActive}) => cx(s.link, isActive && s.active)}
            >
              Company List
            </NavLink>
            <ul className={s.companyStatus}>
              {Object.values(CompanyStatus).map((companyStatus) => (
                <li key={companyStatus}>
                  <NavLink
                    to={`/company-list?status=${companyStatus}`}
                    onClick={handleLinkClick}
                    className={({isActive}) =>
                      cx(
                        s.link,
                        isActive && status === companyStatus && s.active
                      )
                    }
                  >
                    {CompanyStatusLabel[companyStatus]} ({companyPerStatusCount[companyStatus]})
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <NavLink
            to="/company-list"
            onClick={handleLinkClick}
            className={({isActive}) => cx(s.link, isActive && s.active)}
          >
            My Company Profile
          </NavLink>
        )}
        <NavLink
          onClick={handleLinkClick}
          to="/location-list"
          className={({isActive}) => cx(s.link, isActive && s.active)}
        >
          Locations List
        </NavLink>
        {isPrivilegedUser && (
          <NavLink
            onClick={handleLinkClick}
            to="/additional-info"
            className={({isActive}) => cx(s.link, isActive && s.active)}
          >
            Setup Selections
          </NavLink>
        )}
      </div>
      <div className={s.profileContainer}>
        <h3>Profile</h3>
        <div className={s.profileRow}>
          <div className={s.profileCircle}>
            {roleCharacter}
          </div>
          <span>+{phoneNumber}</span>
        </div>
      </div>
      <button onClick={handleLogout} className={s.logoutBtn}>
        Logout
      </button>
    </Sider>
  )
}
