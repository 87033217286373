import React, {ChangeEvent} from 'react';
import mainS from "../../../../../App.module.css";
import {Company} from "../../../../../interfaces/company";

type CompanyFormFieldsProps = {
  company: Company;
  inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof Company) => void;
  errors: Record<keyof Company, string>
}

export const CompanyFormFields = (
  {
    company,
    inputHandler,
    errors
  }: CompanyFormFieldsProps) => {
  const keysOfCompany: (keyof Company)[] = Object.keys(company) as (keyof Company)[];
  return (
    <>
      {keysOfCompany.map((key: keyof Company) => {
        const field:any = company[key];
        if (field.fieldType === "input") {
          return (
            <div key={key} className={mainS.formRow}>
              <p>{field.name}</p>
              <div className={mainS.inputContainer}>
                <input
                  value={field.value}
                  onChange={(e) => {
                    inputHandler(e, key);
                  }}
                />
                <span className={mainS.errorMessage}>{errors[key]}</span>
              </div>
            </div>
          );
        } else if (field.fieldType === "textarea") {
          return (
            <div key={key} className={mainS.formRow}>
              <p style={{ alignSelf: "flex-start" }}>{field.name}</p>
              <div className={mainS.inputContainer}>
            <textarea
              value={field.value}
              onChange={(e) => {
                inputHandler(e, key);
              }}
              rows={5}
            />
                <span className={mainS.errorMessage}>{errors[key]}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  )
}
