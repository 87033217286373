import { AdminHOC } from "../../HOCs/AdminHOC/AdminHOC";
import { Error } from "../../UI/Error/Error";
import {InfoColumn} from "./components/InfoColumn";
import {ChangeInfoModal} from "./components/ChangeInfoModal";

import s from "./AdditionalInfo.module.css";
import {
  AdditionalInfoData,
  InfoType,
  InfoTypeId,
} from "../../../interfaces/additionalInfo";
import {useAdditionalInfoLogic} from "./useAdditionalInfoLogic";

export type AdditionalInfoTreeNode = AdditionalInfoData & {
  children: AdditionalInfoTreeNode[];
};

export interface ActiveItem {
  key: InfoTypeId;
  id: number | null;
  name: string | null;
  category: InfoType;
  parentId: number | null;
}

export interface ParentItem {
  id: number;
  name: string;
  layer: number;
}

const AdditionalInfo = () => {
  const {
    error,
    memberships,
    certifications,
    services,
    categories,
    changeInfo,
    activeItem,
    getParents,
    setError,
    setIsModalOpen,
    isModalOpen,
    setActiveItem,
    deleteInfoItem,
  } = useAdditionalInfoLogic()

  return (
    <AdminHOC title="Setup Selections">
      {error && (
        <Error
          message={error}
          close={() => {
            setError(null);
          }}
        />
      )}
      <div className={s.wrapper}>
        <InfoColumn
          title="Memberships"
          items={memberships}
          idKey={InfoTypeId.Membership}
          openModal={() => setIsModalOpen(true)}
          setActiveItem={setActiveItem}
          deleteInfoItem={deleteInfoItem}
        />
        <InfoColumn
          title="Certifications"
          items={certifications}
          idKey={InfoTypeId.Certification}
          openModal={() => setIsModalOpen(true)}
          setActiveItem={setActiveItem}
          deleteInfoItem={deleteInfoItem}
        />
        <InfoColumn
          title="Services"
          idKey={InfoTypeId.Service}
          items={services}
          openModal={() => setIsModalOpen(true)}
          setActiveItem={setActiveItem}
          deleteInfoItem={deleteInfoItem}
        />
        <InfoColumn
          title="Categories"
          items={categories}
          idKey={InfoTypeId.Category}
          openModal={() => setIsModalOpen(true)}
          setActiveItem={setActiveItem}
          deleteInfoItem={deleteInfoItem}
        />
      </div>
      {isModalOpen && activeItem && (
        <ChangeInfoModal
          onClose={() => setIsModalOpen(false)}
          activeItem={activeItem}
          changeInfo={changeInfo}
          parents={getParents()}
        />
      )}
    </AdminHOC>
  );
};

export default AdditionalInfo;
