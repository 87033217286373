import React from 'react';

//@ts-ignore
import Spinner from '../../../img/spinner.svg';
import s from './Button.module.css';

type ButtonProps = {
  isLoading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({className,children, isLoading, ...props}: ButtonProps) => {

  return (
    <button className={`${s.customButton} ${className}`} {...props}>
      {isLoading ?  <img className={s.spinner} src={Spinner} alt='loader' /> : children}
    </button>
  )
}
