//
import { useCallback, useState } from "react";
import { authAPI } from "../api/configureAxios";

const ACCOUNTS_API_VERSION = "/2-1-0";

export const useAuthApi = () => {
  const [error, setError] = useState(null);

  const config = {
    headers: { "X-API-KEY": "idE09BTJ9R6Au626D4SLA6yvSlSfQOhc7u0O6xED" },
  };

  const getCode = useCallback(
    async ({ phone, deviceId }: { phone: string; deviceId: string }) => {
      try {
        const { data } = await authAPI.post(
          `${ACCOUNTS_API_VERSION}/login`,
          { device_id: deviceId, phone_number: phone },
          config
        );
        if (data.status === "error") {
          throw new Error(data.message || "Something went wrong");
        }
        return data;
      } catch (err: any) {
        console.log(err.message);
        setError(err.message);
        throw new Error(err.message);
      }
    },
    //eslint-disable-next-line
    []
  );

  const verifyCode = useCallback(
    async ({
      phone,
      deviceId,
      code,
    }: {
      phone: string;
      deviceId: string;
      code: string;
    }) => {
      try {
        const { data } = await authAPI.post(
          `${ACCOUNTS_API_VERSION}/login/verify`,
          { device_id: deviceId, phone_number: phone, OTP: code },
          config
        );
        if (data.status === "error" || !data.token) {
          throw new Error(data.message || "Something went wrong");
        }
        return data;
      } catch (err: any) {
        console.log(err.message);
        setError(err.message);
        throw new Error(err.message);
      }
    },
    //eslint-disable-next-line
    []
  );

  return { error, setError, getCode, verifyCode };
};
