import React from 'react';
import CustomCheckbox from "../../../../UI/CustomCheckbox/CustomCheckbox";
import {CompanyFormI} from "../../companyFormTypes";


type MembershipsCertificatesProps = {
  company: CompanyFormI;
  membershipCertificate: {id: number, name: string}[]
  checkHandler: (type: 'certifications' | 'memberships', id: number) => void;
  type: 'certifications' | 'memberships'
}

export const MembershipsCertificates = (
  {
    company,
    membershipCertificate,
    checkHandler,
    type
  }: MembershipsCertificatesProps) => {
  return (
    <>
      {
        membershipCertificate.map((el) => {
          return (
            <div key={el.id}>
              <CustomCheckbox
                checked={company[type].value.includes(el.id)}
                onChange={() => {
                  checkHandler(type, el.id);
                }}
                label={el.name}
              />
            </div>
          );
        })
      }
    </>
  )
}
