import {useCallback, useState, FormEvent} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {mainAPI} from "../../../../api/configureAxios";
import {useDispatch} from 'react-redux'
import {addBanner} from "../../../../store/banner/bannerSlice";

export const useStripeLogic = () => {
  const [loading, setLoading] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (
    event: FormEvent<HTMLFormElement>,
    locationsNumber: number,
    companyId: number,
    onClose: () => void,
    refetchCompanies: () => void
  ) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try{
      const basePrice = 1200; // Base price for premium company subscription
      const additionalCostPerLocation = 100; // Additional cost per premium location

      const totalAdditionalCost = locationsNumber * additionalCostPerLocation;

      const totalSubscriptionPrice = basePrice + totalAdditionalCost;

      setLoading(true);
      //@ts-ignore
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
      if (result.error) {
        console.error(result.error.message);
        setLoading(false);
      } else {
        const paymentMethodId = result.paymentMethod.id;
        const config = {
          headers: { 'Content-Type': 'application/json' },
        }
        const response = await mainAPI.post('/subscription/create-subscription', {
            paymentMethodId,
            locationsNumber,
            totalSubscriptionPrice,
            companyId,
          },
          config
        )
        //@tsIgnore
        if (response.data.error) {
          dispatch(addBanner({bannerMessage: 'Something went wrong', bannerStatus: 'error'}))
        } else {
          onClose()
          dispatch(addBanner({bannerMessage: 'Subscription active', bannerStatus: 'info'}))
          refetchCompanies()
        }
        setLoading(false);
      }
    }catch (e){
      console.error(e);
      dispatch(addBanner({bannerMessage: 'Something went wrong', bannerStatus: 'error'}))
    }

  },[stripe, elements, dispatch])

  const getPaymentLink = useCallback(async (
    locationsNumber: number,
    companyId: number
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const basePrice = 1200;
    const additionalCostPerLocation = 100;

    const totalAdditionalCost = locationsNumber * additionalCostPerLocation;

    const totalSubscriptionPrice = basePrice + totalAdditionalCost;
    setIsLinkLoading(true);
    const config = {
      headers: { 'Content-Type': 'application/json' },
    }
    const response = await mainAPI.post('/subscription/create-subscription-link', {
        locationsNumber,
        totalSubscriptionPrice,
        companyId,
      },
      config
    )
    setIsLinkLoading(false);
    if (response.data.error) {
      console.error(response.data.error);
    } else {
      console.log('Subscription created:', response.data.subscription);
      return response.data.checkoutLink
    }
  },[stripe, elements])

  return {
    loading,
    handleSubmit,
    getPaymentLink,
    isLinkLoading,
  }
}
