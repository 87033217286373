import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";

import {BannerInfo, removeBanner} from "../../../store/banner/bannerSlice";

//@ts-ignore
import closeIcon from '../../../img/xIcon.svg';
import s from './Banner.module.css';

type BannerProps = {
  banner: BannerInfo
  top: number
}

const DEFAULT_TOP_OFFSET = 30

export const Banner = ({banner, top} : BannerProps) => {
  const dispatch = useDispatch();
  const topOffset = DEFAULT_TOP_OFFSET + top;

  const classNameMap = {
    'info': s.infoBanner,
    'error': s.errorBanner,
    'success': s.successBanner
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeBanner(banner.id))
    }, 4500)

  }, [dispatch, banner.id])

  return (
    <div style={{top: topOffset}} className={`${s.banner} ${classNameMap[banner.bannerStatus]}`}>
      {banner.bannerMessage}
      <img onClick={() => dispatch(removeBanner(banner.id))} src={closeIcon} className={s.icon} alt="close"/>
    </div>
  )
}
