import {Breadcrumb, Layout} from "antd";
import React, {} from "react";
import {NavLink} from "react-router-dom";

import s from "./AdminHOC.module.css";

import {useAdminHOCLogic} from "./useAdminHOCLogic";
import {Sidebar} from "./components/Sidebar";
import {Footer} from "../../UI/Footer";
import {Select} from "../../UI/Select";

const {Header, Content} = Layout;

interface Props {
  children?: React.ReactNode;
  title: string;
  filterComponent?: React.ReactNode ;
}

export const AdminHOC = ({children, title, filterComponent}: Props) => {
  const {
    companies,
    selectedCompany,
    host,
    isUserAdmin,
    colorBgContainer,
    isPrivilegedUser,
    handleCompanySelect,
  } = useAdminHOCLogic();

  return (
    <>
      <Sidebar/>
      <Layout className={s.siteLayout}>
        {isPrivilegedUser && (
          <Header
            className={s.header}
            style={{
              background: colorBgContainer
            }}
          >
            <Select
              value={selectedCompany}
              onChange={handleCompanySelect}
              options={companies.map(company =>
                ({value: company.companyId, key: company.companyId, label: company.companyName}))}
            >
              <option value={0} key={0}>
                Select Company
              </option>
            </Select>
            <NavLink className={s.addCompanyLink} to="/company">
              +
            </NavLink>
          </Header>
        )}
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <div className={s.subheaderWrapper}>
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
            {+host === 0 && isUserAdmin && filterComponent}
          </div>
          <div className={s.contentWrapper}>
            {children}
          </div>
        </Content>
        <Footer />
      </Layout>
    </>
  );
};
