import { configureStore } from '@reduxjs/toolkit'
import sessionSlice from "./store/session/sessionSlice";
import companySlice from "./store/company/companySlice";
import bannerSlice from "./store/banner/bannerSlice";

export const store = configureStore({
  reducer: {
    session: sessionSlice,
    company: companySlice,
    banner: bannerSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
