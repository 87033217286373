import { useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import s from "./index.module.css";
import { PopUp } from "../../../../UI/PopUp/PopUp";
import { ActiveItem, ParentItem } from "../../AdditionalInfo";
import { InfoTitles, InfoTypeId } from "../../../../../interfaces/additionalInfo";

const capitalizeFirstLetter = (name = "") => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

interface Props {
  onClose: () => void;
  activeItem: ActiveItem;
  changeInfo: (
    payload: { name: string; parent_id: number | null } & {
      [key in InfoTypeId]?: number;
    },
    activeCategoryName: string,
    method: string
  ) => void;
  parents: ParentItem[];
}

export const ChangeInfoModal = ({
  onClose,
  activeItem,
  changeInfo,
  parents,
}: Props) => {
  const [infoName, setInfoName] = useState(activeItem.name || "");
  const [parentId, setParentId] = useState(activeItem.parentId ?? -1);
  const activeCategoryName = InfoTitles[activeItem.key];
  const [changed, setChanged] = useState(false);

  const handleSave = async () => {
    let method;
    const payload = {
      name: infoName,
      parent_id: parentId === -1 ? null : parentId,
    };
    if (activeItem.id !== null) {
      //@ts-ignore
      payload[activeItem.key] = activeItem.id;
      method = "put";
    } else {
      method = "post";
    }
    try {
      await changeInfo(payload, activeCategoryName, method);
      onClose();
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
    <PopUp className={s.modalContent} onClose={onClose}>
      <CloseCircleFilled className={s.closeButton} onClick={() => onClose()} />
      <h1>
        {`${capitalizeFirstLetter(activeCategoryName)} - ${
          activeItem.id === null ? "Create new" : "Edit"
        }`}
      </h1>
      <label className={s.modalLabel}>{`${capitalizeFirstLetter(
        activeCategoryName
      )} Name:`}</label>
      <input
        className={s.modalInput}
        type="text"
        value={infoName}
        onChange={(e) => {
          setInfoName(e.target.value);
          setChanged(e.target.value !== "");
        }}
      />
      {!!parents.length && (
        <>
          <label
            className={s.modalLabel}
          >{`Select Parent ${capitalizeFirstLetter(
            activeCategoryName
          )}`}</label>
          <div className={s.selectWrapper}>
            <select
              value={parentId}
              onChange={(e) => {
                setParentId(+e.target.value);
                setChanged(true);
              }}
            >
              <option value={-1}>no parent</option>
              {parents.map((parent) => (
                <option value={parent.id} key={parent.id}>
                  {`${Array(parent.layer).fill("-").join("")} ${
                    parent.name
                  } (#${parent.id})`}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
      <button
        className={`${s.saveButton} ${!changed && s.disabledBtn}`}
        onClick={() => handleSave()}
        disabled={!changed}
      >
        Save {activeCategoryName}
      </button>
    </PopUp>
  );
};
