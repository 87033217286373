//@ts-nocheck
import { Navigate } from "react-router-dom";
import s from "./CompanyInfo.module.css";
import mainS from "../../../App.module.css";
import { AdminHOC } from "../../HOCs/AdminHOC/AdminHOC";
import { Error } from "../../UI/Error/Error";
import ImageUploader from "../../UI/ImageUploader";
import {useCompanyInfoLogic} from "./useCompanyInfoLogic";
import {CompanyFormFields} from "./components/CompanyFormFields";
import {MembershipsCertificates} from "./components/MembershipsCertificates";
import {CategoriesServices} from "./components/CategoriesServices";

export const INITIAL_ERRORS = {
  name: "",
  description: "",
  categories: "",
  services: "",
};

export const CompanyInfo = () => {
  const {
    company,
    error,
    setError,
    success,
    inputHandler,
    errors,
    setCompany,
    categories,
    certifications,
    memberships,
    services,
    goToHomePage,
    companyId,
    submitHandler,
    setFile,
  } = useCompanyInfoLogic();

  const checkHandler = (field, id) => {
    const copy = { ...company };
    const index = copy[field].value.findIndex((el) => el === id);
    if (index === -1) {
      copy[field].value.push(id);
    } else {
      copy[field].value.splice(index, 1);
    }
    setCompany(copy);
    setErrors({
      ...errors,
      [field]: validate(field, copy[field].value),
    });
  };

  if (goToHomePage) {
    return <Navigate to="/" />;
  }

  return (
    <AdminHOC title="Company Info">
      {error && (
        <Error
          message={error}
          close={() => {
            setError(null);
          }}
        />
      )}
      {success && (
        <Navigate to="/company-list" />
      )}
      <div className={mainS.container}>
        <ImageUploader imageUrl={company.logo.value ?? ""} setFile={setFile} />
        <form onSubmit={submitHandler}>
          <CompanyFormFields company={company} inputHandler={inputHandler} errors={errors} />
          <div className={s.checkboxesList}>
            <div style={{paddingRight: 12, borderRight: '1px solid #cbcbcb', marginRight: 12, alignItems: 'flex-start'}} className={mainS.formRow}>
              <p className={s.label} style={{ alignSelf: "flex-start" }}>Memberships:</p>
              <div className={s.pointList}>
                <MembershipsCertificates
                  company={company}
                  membershipCertificate={memberships.map(m => ({id: m.memberships_id, name: m.name}))}
                  checkHandler={checkHandler}
                  type='memberships'
                />
                <span className={mainS.errorMessage}>{errors.memberships}</span>
              </div>
            </div>
            <div className={mainS.formRow} style={{ alignSelf: "flex-start" }}>
              <p className={s.label} style={{ alignSelf: "flex-start" }}>Certifications:</p>
              <div className={s.pointList}>
                <MembershipsCertificates
                  company={company}
                  membershipCertificate={certifications.map(m => ({id: m.certifications_id, name: m.name}))}
                  checkHandler={checkHandler}
                  type='certifications'
                />
                <span className={mainS.errorMessage}>
                  {errors.certifications}
                </span>
              </div>
            </div>
          </div>
          <div className={s.checkboxesList}>
            <div style={{paddingRight: 12, borderRight: '1px solid #cbcbcb', marginRight: 12, alignItems: 'flex-start'}} className={mainS.formRow}>
              <p className={s.label} style={{ alignSelf: "flex-start" }}>Services:</p>
              <div className={s.pointList}>
                <CategoriesServices
                  company={company}
                  servicesCategories={services.map(s => ({id: s.service_id, ...s}))}
                  checkHandler={checkHandler}
                  type='services'
                />
                <span className={mainS.errorMessage}>{errors.services}</span>
              </div>
            </div>
            <div className={mainS.formRow} style={{ alignSelf: "flex-start" }}>
              <p className={s.label} style={{ alignSelf: "flex-start" }}>Categories:</p>
              <div className={s.pointList}>
                <CategoriesServices
                  company={company}
                  servicesCategories={categories.map(c => ({id: c.categories_id, ...c}))}
                  checkHandler={checkHandler}
                  type='categories'
                />
                <span className={mainS.errorMessage}>{errors.categories}</span>
              </div>
            </div>
          </div>
          <button type="submit">
            {companyId ? "Update Company" : "Add Company"}
          </button>
        </form>
      </div>
    </AdminHOC>
  );
};
