//@ts-nocheck
import InputField from "../../../../UI/Input/InputField";
import MapContainer from "../../../../UI/MapContainer/MapContainer";
import mainS from "../../../../../App.module.css";
import s from './index.module.css';
import useCities from "../../hooks/useCities";

const FormFields = ({
  location,
  showChart,
  changeHandler,
  changeCoordValues,
  companies,
  selectHandler,
  companyId,
  locationId,
}) => {
  const {cities} = useCities();

  const Fields = Object.keys(location).map((key) => {
    if (key === "companyId" || key === "locationId" || key === "locationCode") {
      return null;
    }
    if (key === "locationCity") {
      return (
        <div>
          <p>City: </p>
          <select
            value={location[key].value}
            onChange={(e) => {
              changeHandler(e, key);
            }}
          >
            {!location[key].value && <option value={""}>Select City</option>}
            {(
              cities[location["locationCountry"].value]?.[
                location["locationProvince"].value
                ] ?? []
            ).map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>
        </div>
      );
    }
    if (key === "locationCountry") {
      return (
        <>
          <div>
            <p>Country:</p>
            <select
              value={location[key].value}
              onChange={(e) => {
                changeHandler(e, key);
                changeHandler({ target: { value: "" } }, "locationProvince");
                changeHandler({ target: { value: "" } }, "locationCity");
              }}
              className={location.companyId.valid ? null : mainS.invalidField}
            >
              {Object.keys(cities).map((country) => (
                <option value={country}>{country}</option>
              ))}
            </select>
          </div>
        </>
      );
    }
    if (key === "locationProvince") {
      const provinceArr = Object.keys(
        cities[location["locationCountry"].value] ?? {}
      );

      return (
        <>
          <div>
            <p>Province:</p>
            <select
              value={location[key].value}
              onChange={(e) => {
                changeHandler(e, key);
                changeHandler({ target: { value: "" } }, "locationCity");
              }}
              className={location.companyId.valid ? null : mainS.invalidField}
            >
              {!location[key].value && (
                <option value={""}>Select Province</option>
              )}
              {provinceArr.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>
        </>
      );
    }
    if (key === "locationLatitude") {
      return (
        <>
          <InputField
            key={key}
            name={location[key].name}
            value={location[key].value}
            className={location[key].valid ? null : mainS.invalidField}
            onChange={(e) => {
              changeHandler(e, key);
            }}
            min={-90}
            max={90}
            step="0.000000000000000001"
            type="number"
            errMessage="Wrong latitude"
            required={true}
          />
        </>
      );
    }
    if (key === "locationLongitude") {
      return (
        <>
          <InputField
            key={key}
            step="0.000000000000000001"
            name={location[key].name}
            value={location[key].value}
            className={location[key].valid ? null : mainS.invalidField}
            onChange={(e) => {
              changeHandler(e, key);
            }}
            min={-180}
            max={180}
            errMessage="Wrong Longitude"
            type="number"
            required={true}
          />
          <div>
            <p>Pin on map:</p>
            {showChart && (
              <div className={`${mainS.mapContainer} ${s.mapContainer}`}>
                <MapContainer
                  changeCoordValues={changeCoordValues}
                  containerStyle={{
                    position: 'relative',
                    width: '650px',
                    height: '350px'
                  }}
                  markerCoord={{
                    lat: parseFloat(location.locationLatitude.value || 51),
                    lng: parseFloat(location.locationLongitude.value || -114),
                  }}
                />
              </div>
            )}
          </div>
        </>
      );
    }
    return (
      <InputField
        key={key}
        name={location[key].name}
        value={location[key].value}
        className={location[key].valid ? null : mainS.invalidField}
        onChange={(e) => {
          changeHandler(e, key);
        }}
        required={true}
        pattern={location[key].regex}
      />
    );
  });

  return (
    <>
      <div>
        <p>Company:</p>
        <select
          value={location.companyId.value}
          onChange={selectHandler}
          disabled={companyId && locationId}
          className={location.companyId.valid ? null : mainS.invalidField}
        >
          <option value="" disabled hidden>
            Select Company
          </option>
          {companies
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((company) => {
              return (
                <option value={company.id} key={company.id}>
                  {company.name}
                </option>
              );
            })}
        </select>
      </div>
      {Fields}
    </>
  );
};

export default FormFields;
